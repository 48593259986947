import { createContext, useContext, useMemo } from "react";
const PrefetchContext = createContext({ data: {} });
export const PrefetchProvider = ({ children, data }) => {
    const value = useMemo(() => ({ data }), [data]);
    return <PrefetchContext.Provider value={value}>{children}</PrefetchContext.Provider>;
};
export const usePrefetch = (key) => {
    var _a, _b;
    return ({
        data: (_b = (_a = useContext(PrefetchContext)) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[key],
    });
};
