import classNames from "classnames";
import React from "react";
export const AlertBoxBase = ({ AlertBoxWrapperCss }) => {
    const AlertBoxFC = ({ text, isWarning, isError, textColor, isMinOrderMessage, textTransform, isGrayInfo, className, }) => {
        return (<div className={classNames("my-5 w-full p-3.75 uppercase", isGrayInfo
                ? "text-gray-alertBox"
                : isWarning
                    ? "text-yellow-mcKenzie"
                    : isError
                        ? "text-red-error"
                        : "text-try-dracula", isGrayInfo
                ? "bg-gray-bg"
                : isMinOrderMessage
                    ? "bg-orange-light"
                    : isWarning
                        ? "bg-yellow-light"
                        : isError
                            ? "bg-pink-salmon"
                            : "bg-white", AlertBoxWrapperCss, className)} style={{
                color: textColor,
                textTransform: textTransform || "none",
            }}>
                {text}
            </div>);
    };
    return AlertBoxFC;
};
