import { asImageSrc, isFilled } from "@prismicio/helpers";
import { typedBoolean } from "../helper";
export const transBrands = ({ body, }) => [...(body || [])]
    .map((slice) => {
    var _a, _b;
    if (!isFilled.image(slice.primary.brand_logo))
        return;
    if (!slice.primary.brand_id)
        return;
    return {
        brandId: slice.primary.brand_id,
        alt: slice.primary.brand_logo.alt,
        url: asImageSrc(slice.primary.brand_logo),
        width: ((_a = slice.primary.brand_logo.dimensions) === null || _a === void 0 ? void 0 : _a.width) || 0,
        height: ((_b = slice.primary.brand_logo.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 0,
    };
})
    .filter(typedBoolean);
