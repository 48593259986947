import React, { useEffect, useState } from "react";
import { uid } from "react-uid";
const CountdownFC = (fields) => {
    const calculateTimeLeft = () => {
        const difference = fields.end.getTime() - new Date().getTime();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                dagen: Math.floor(difference / (1000 * 60 * 60 * 24)),
                uur: Math.floor((difference / (1000 * 60 * 60)) % 24),
                min: Math.floor((difference / 1000 / 60) % 60),
                sec: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });
    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(<span key={uid(interval)}>
                {timeLeft[interval]} {interval}{" "}
            </span>);
    });
    return <span>{timerComponents.length && timerComponents}</span>;
};
export const Countdown = React.memo(CountdownFC);
