import { v4 } from "uuid";
import { sleep } from "../helper";
import { getTwProfilekey, localStorage } from "../localStorage";
export class JogError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
    }
}
export const AWSClient = (_baseUrl) => {
    const baseUrl = _baseUrl.replace(/\/$/, "");
    return async ({ uri, ...request }) => {
        var _a;
        const headers = { ["x-correlation-id"]: "", ["x-tw-profilekey"]: "" };
        if (typeof window !== "undefined") {
            const correlationId = (_a = localStorage.getItem("x-correlation-id", { id: "" })) === null || _a === void 0 ? void 0 : _a.id;
            if (!correlationId) {
                const newId = v4();
                localStorage.setItem("x-correlation-id", { id: newId });
                headers["x-correlation-id"] = newId;
            }
            else {
                headers["x-correlation-id"] = correlationId;
            }
            headers["x-tw-profilekey"] = getTwProfilekey() || "";
            const token = localStorage.getItem("cognito-auth");
            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }
        }
        const response = await fetch(`${baseUrl}${uri}`, {
            ...request,
            body: request.body ? JSON.stringify(request.body) : null,
            headers: request.headers ? { ...request.headers, ...headers } : headers,
        });
        if (response.ok) {
            return {
                ...response,
                body: response.status === 204 ? null : await response.json(),
                statusCode: response.status,
            };
        }
        if ([401, 403].includes(response.status)) {
            window.location.href = "/login";
            await sleep(5000);
        }
        throw new JogError(response.statusText, response);
    };
};
