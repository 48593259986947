export const recaptchaValidateToken = async (token) => {
    const response = await fetch("/api/availableWithCaptcha", { method: "POST", body: new URLSearchParams({ token }) });
    if (!response.ok)
        return false;
    try {
        const result = await response.json();
        return result.success && result.score > 0.3;
    }
    catch (e) {
        console.error(e);
        return false;
    }
};
