import { localStorage, sessionController } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { signOutUser } from "../components";
export const useSessionInit = () => {
    const session = useSession();
    useEffect(() => {
        var _a, _b, _c, _d;
        if (session.status === "unauthenticated") {
            sessionController().clear();
        }
        if (session.status === "authenticated") {
            const expires = session.data.expires;
            localStorage.setItem("name", (_b = (_a = session.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.name);
            localStorage.setItem("email", (_d = (_c = session.data) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.email);
            if (expires && new Date(expires).getTime() < new Date().getTime()) {
                signOutUser();
            }
            else {
                sessionController().set(session.data);
            }
        }
    }, [session.data, session.status]);
};
