import { asHTML, asImageSrc, asLink, asText, isFilled } from "@prismicio/helpers";
import { typedBoolean } from "../helper";
export const transLayout = (data) => {
    var _a, _b, _c;
    const headerLinks = [...(data.body || [])]
        .map((i) => i.items.map(({ label, link }) => ({
        label: asText(label) || "",
        link: asLink(link) || "",
    })))
        .flat()
        .filter((i) => i.link && i.label);
    const emailSubscription = ((_a = [...(data.body3 || [])].at(0)) === null || _a === void 0 ? void 0 : _a.primary) || null;
    const countdown = [...(data.body2 || [])].at(0);
    return {
        cartPopupShowTime: (data.cart_popup_show_time || 5) * 1000,
        headerLinks,
        countdown: countdown
            ? {
                excludePageGroup: (countdown.primary.exclude_page_group || "").split(",") || [],
                display: countdown.primary.display,
                background: countdown.primary.background_color,
                color: countdown.primary.text_color,
                fontSize: `${countdown.primary.font_size}px`,
                items: [...(countdown.items || [])].map(({ start_time, end_time, text, countdown }) => ({
                    start: start_time,
                    end: end_time,
                    text,
                    countdown,
                })),
            }
            : null,
        emailSubscription,
        logo: ((_c = (_b = [...(data.body4 || [])].at(0)) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.logo) || null,
        footer: {
            columns: [...(data.body1 || [])]
                .map((slice) => slice.slice_type === "footer_column"
                ? {
                    title: asText(slice.primary.column_title),
                    url: asLink(slice.primary.column_title_url) || "",
                    items: slice.items
                        .map(({ label, label_url }) => ({
                        url: asLink(label_url) || "",
                        text: asText(label),
                    }))
                        .filter((i) => i.url && i.text),
                }
                : null)
                .filter(typedBoolean),
            logos: [...(data.body1 || [])]
                .map((slice) => slice.slice_type === "footer_logos"
                ? slice.items
                    .filter((i) => isFilled.image(i.image))
                    .map((i) => {
                    var _a, _b;
                    return ({
                        src: asImageSrc(i.image) || "",
                        width: ((_a = i.image.dimensions) === null || _a === void 0 ? void 0 : _a.width) || 0,
                        height: ((_b = i.image.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 0,
                    });
                })
                    .filter((i) => i.src)
                : null)
                .filter(typedBoolean),
            legal: [...(data.body1 || [])]
                .map((slice) => slice.slice_type === "footer_legal_row"
                ? slice.items
                    .map(({ url, label }) => ({
                    url: asLink(url) || "",
                    label: asText(label),
                }))
                    .filter((i) => i.url && i.label)
                : null)
                .filter(typedBoolean),
        },
    };
};
const formatThirdCategoryItems = (thirdCategoryItems) => thirdCategoryItems.map((thirdItem) => ({
    thirdCategoryNameString: asText(thirdItem.third_category_name) || "",
    thirdCategoryName: asHTML(thirdItem.third_category_name) || "",
    thirdCategoryUrl: asLink(thirdItem.third_category_url) || "",
    thirdCategoryTextColor: (thirdItem === null || thirdItem === void 0 ? void 0 : thirdItem.third_category_color) || null,
    thirdLabelBgColor: (thirdItem === null || thirdItem === void 0 ? void 0 : thirdItem.third_label_bg_color) || null,
    thirdLabelText: (thirdItem === null || thirdItem === void 0 ? void 0 : thirdItem.third_label_text) || "",
    thirdLabelTextColor: (thirdItem === null || thirdItem === void 0 ? void 0 : thirdItem.third_label_text_color) || null,
    hasBottomBorder: thirdItem.third_category_has_bottom_border || false,
}));
const formatSubCategoryItems = (subCategoryItems) => subCategoryItems.map((subItem) => {
    const subCategoryItem = subItem.primary;
    return {
        subCategoryNameString: asText(subCategoryItem.sub_category_name) || "",
        subCategoryName: asHTML(subCategoryItem.sub_category_name) || "",
        subCategoryUrl: asLink(subCategoryItem.sub_category_url) || "",
        subCategoryTextColor: (subCategoryItem === null || subCategoryItem === void 0 ? void 0 : subCategoryItem.sub_category_text_color) || null,
        subLabelBgColor: (subCategoryItem === null || subCategoryItem === void 0 ? void 0 : subCategoryItem.sub_label_bg_color) || null,
        subLabelText: (subCategoryItem === null || subCategoryItem === void 0 ? void 0 : subCategoryItem.sub_label_text) || "",
        subLabelTextColor: (subCategoryItem === null || subCategoryItem === void 0 ? void 0 : subCategoryItem.sub_label_text_color) || null,
        hasBottomBorder: (subCategoryItem === null || subCategoryItem === void 0 ? void 0 : subCategoryItem.sub_category_has_bottom_border) || false,
        thirdCategoryItems: formatThirdCategoryItems([...subItem.items]),
    };
});
export const transDesktopNav = (nav_item) => {
    if (!nav_item.data)
        return null;
    if (!nav_item.slug)
        return null;
    if (!nav_item.id)
        return null;
    return {
        id: nav_item.id,
        slug: nav_item.slug,
        categoryName: asHTML(nav_item.data.category_name) || "",
        categoryUrl: asLink(nav_item.data.category_url) || "",
        categoryTextColor: nav_item.data.category_text_color || null,
        bannerImage: asImageSrc(nav_item.data.banner_image) || "",
        bannerUrl: asLink(nav_item.data.banner_url) || "",
        subCategoryItems: formatSubCategoryItems([...(nav_item.data.body || [])]),
    };
};
export const transMobileNav = (nav_item) => {
    if (!nav_item.data)
        return null;
    if (!nav_item.slug)
        return null;
    if (!nav_item.id)
        return null;
    return {
        id: nav_item.id,
        slug: nav_item.slug,
        categoryNameString: asText(nav_item.data.category_name) || "",
        categoryName: asHTML(nav_item.data.category_name) || "",
        categoryUrl: asLink(nav_item.data.category_url) || "",
        categoryTextColor: nav_item.data.category_text_color || null,
        hasBottomBorder: nav_item.data.has_bottom_border || false,
        labelBgColor: nav_item.data.label_bg_color || null,
        labelTextColor: nav_item.data.label_text_color || null,
        labelText: nav_item.data.label_text || "",
        subCategoryItems: formatSubCategoryItems([...(nav_item.data.body || [])]),
    };
};
export const transTranslations = (translations) => [...(translations || [])].reduce((p, { key, value }) => {
    if (!key)
        return p;
    return { ...p, [key]: value || "" };
}, {});
