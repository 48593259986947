import classNames from "classnames";
export const Arrow = ({ color, direction = "right", size = "small", borderWidth, className }) => {
    return (<span className={classNames("border-gray-base", {
            "h-2 w-2 border-r-2 border-t-2": size === "small",
            "h-2.5 w-2.5 border-r-3 border-t-3": size === "middle",
            "h-6 w-6 border-r-3 border-t-3": size === "large",
        }, {
            "rotate-45": direction === "right",
            "rotate-135": direction === "down",
            "rotate-225": direction === "left",
            "rotate-315": direction === "up",
        }, className)} style={{ borderColor: color, borderRightWidth: borderWidth, borderTopWidth: borderWidth }}/>);
};
