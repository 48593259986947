export const createLoggedUserCartBase = ({ execute, setToken }) => async ({ customerId, loyaltyCardNumber }) => {
    const body = {
        currency: "EUR",
        inventoryMode: "ReserveOnOrder",
        customerId,
        locale: process.env.NEXT_PUBLIC_LOCALE,
        store: {
            key: process.env.NEXT_PUBLIC_STORE_ID,
            type: "store",
        },
    };
    const jcFriendCustomObject = {
        type: {
            typeId: "type",
            key: "loyaltyCardType",
        },
        fields: {
            loyaltyCardNumber,
        },
    };
    const requestWithToken = await setToken({
        uri: `/ctp/carts`,
        method: "POST",
        body: loyaltyCardNumber ? { ...body, custom: jcFriendCustomObject } : body,
    });
    return execute(requestWithToken);
};
