import { handleCartError } from "../helper";
export const addBillingAddressBase = ({ client }) => {
    return async (cartId, version, billingAddress) => {
        const action = {
            action: "setBillingAddress",
            address: {
                country: billingAddress.country,
                firstName: billingAddress.name,
                lastName: `${billingAddress.insertion || ""} ${billingAddress.familyName}`.trim(),
                postalCode: billingAddress.postalCode,
                streetNumber: billingAddress.houseNumber,
                streetName: billingAddress.streetName,
                city: billingAddress.city,
                additionalStreetInfo: billingAddress.addition,
                phone: billingAddress.phoneNumber,
                email: billingAddress.email,
            },
        };
        const request = (version) => client.execute({
            method: "POST",
            uri: `/ctp/carts/${cartId}`,
            body: {
                version: version,
                actions: [action],
            },
        });
        return handleCartError(request, version, 5);
    };
};
