import { JCRichText } from "@/components/RichText/RichText";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ClientOnly, Container, Countdown } from "@jog/react-components";
import { getBaseCategoryFromPrettyPath } from "@jog/shared";
import { asDate } from "@prismicio/helpers";
import dayjs from "dayjs";
import { useRouter } from "next/router";
export const TopBanner = () => {
    var _a, _b, _c, _d, _e;
    const router = useRouter();
    const layout = useLayout();
    const pageUrl = ((_b = (_a = router.asPath) === null || _a === void 0 ? void 0 : _a.split("?")) === null || _b === void 0 ? void 0 : _b[0]) || "";
    const asPath = (_c = getBaseCategoryFromPrettyPath(pageUrl)) === null || _c === void 0 ? void 0 : _c.join("/");
    const countdown = (_d = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _d === void 0 ? void 0 : _d.countdown;
    if (!(countdown === null || countdown === void 0 ? void 0 : countdown.display))
        return null;
    const hiddenTopBanner = countdown.excludePageGroup.includes(asPath);
    if (hiddenTopBanner || !((_e = countdown.items) === null || _e === void 0 ? void 0 : _e.length))
        return null;
    return (<div style={{ background: countdown.background }}>
            <Container>
                <ClientOnly>
                    <div>
                        {countdown.items.map((item, index) => {
            const currentDate = dayjs().toDate();
            const startDate = asDate(item.start);
            const endDate = asDate(item.end);
            const countdownBanner = startDate < currentDate && endDate > currentDate && item.countdown;
            const textBanner = startDate < currentDate && endDate > currentDate;
            return ((countdownBanner || textBanner) && (<div className="p-2.5 text-center" style={{ color: countdown.color, fontSize: countdown.fontSize }} key={index}>
                                        {countdownBanner && (<p className="mb-0 inline-block pr-1.25">
                                                Nog <Countdown end={asDate(item.end)}/>
                                            </p>)}
                                        {textBanner && (<JCRichText field={item.text} paragraphClass="inline-block mb-0 text-base" linkStyle={{ borderColor: countdown.color }} linkClass="text-inherit border-b-2 hover:border-0 !no-underline"/>)}
                                    </div>));
        })}
                    </div>
                </ClientOnly>
            </Container>
        </div>);
};
