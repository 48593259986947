import { configure } from "mobx";
import { Provider, enableStaticRendering } from "mobx-react";
import cartStore from "./cartStore";
import gtmStore from "./gtmStore";
import purchaseStore from "./purchaseStore";
import userStore from "./userStore";
import voucherStore from "./voucherStore";
enableStaticRendering(typeof window === "undefined");
configure({
    enforceActions: "never",
});
const stores = {
    cartStore,
    userStore,
    voucherStore,
    gtmStore,
    purchaseStore,
};
export function StoresProvider({ children }) {
    return <Provider {...stores}>{children}</Provider>;
}
