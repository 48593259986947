import { Favorite as FavoriteIcon } from "@/components/Icon/Favorite";
import { useWishlist } from "@/lib/store/wishlist";
import { gtmAddToWishlist, gtmRemoveFromWishlist } from "@jog/shared";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
export const Favorite = ({ productId, gtmId, name, price, className = "bottom-4 right-4", }) => {
    var _a;
    const addWishlistItem = useWishlist.use.addWishlistItem();
    const removeProductFromWishlist = useWishlist.use.removeWishlistItem();
    const favoriteProducts = (_a = useWishlist.use.simpleWishlist()) === null || _a === void 0 ? void 0 : _a.lineItems;
    const lineItemId = useMemo(() => { var _a; return (_a = favoriteProducts === null || favoriteProducts === void 0 ? void 0 : favoriteProducts.find((lineItem) => lineItem.productId === productId)) === null || _a === void 0 ? void 0 : _a.id; }, [productId, favoriteProducts]);
    const onClick = useCallback(async (e) => {
        e.preventDefault();
        if (!lineItemId) {
            gtmAddToWishlist([{ id: gtmId, name, price }]);
            await addWishlistItem(productId);
        }
        else {
            gtmRemoveFromWishlist([{ id: gtmId, name, price }]);
            await removeProductFromWishlist(lineItemId);
        }
    }, [addWishlistItem, gtmId, lineItemId, name, price, productId, removeProductFromWishlist]);
    return (<div className={classNames("absolute flex cursor-pointer items-center justify-center", className)} onClick={onClick}>
            <FavoriteIcon className={classNames(lineItemId ? "fill-green-dark text-green-dark" : "fill-white text-black")}/>
        </div>);
};
