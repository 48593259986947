import { NextImage, StoreWorkTime, useI18n } from "@jog/react-components";
import { typedBoolean } from "@jog/shared";
import classNames from "classnames";
import { CheckMarks } from "./CheckMark";
export const ShopInfo = ({ store, imageSize }) => {
    const { $t } = useI18n();
    const { town, address, image, postalCode, phone, openings, storeType, hasTailor, hasCoffee } = store;
    const imageSizeRatio = { base: 1, lg: 1.5 };
    const style = {
        "--lg": `${180 * imageSizeRatio[imageSize]}px`,
        "--xl": `${240 * imageSizeRatio[imageSize]}px`,
        "--xxl": `${280 * imageSizeRatio[imageSize]}px`,
    };
    return (<div className="relative" style={image ? style : {}}>
            {image && (<div className="mb-5 lg:absolute lg:right-0 lg:top-0 lg:mb-0 lg:w-[var(--lg)] lg:pl-5 xl:w-[var(--xl)] 2xl:w-[var(--xxl)]">
                    <NextImage blur={false} src={image} width={300} height={200} alt="Store image" className="h-full w-full"/>
                </div>)}
            <div className={classNames({ "lg:pr-[var(--lg)] xl:pr-[var(--xl)] 2xl:pr-[var(--xxl)]": image })}>
                <div className="mb-2.5 font-primary font-bold uppercase">
                    {[storeType, town].filter(typedBoolean).join(" ")}
                </div>
                <div className="font-bold">{address}</div>
                <div className="font-bold">{[postalCode, town].filter(typedBoolean).join(" ")}</div>
                <div className="my-5">
                    Tel:
                    <a href={`tel:${phone}`}>{phone}</a>
                </div>
            </div>

            <CheckMarks className="mb-5" store={store}/>

            <StoreWorkTime openings={openings}/>
            {hasTailor && (<div className="mt-5">
                    <div className="font-bold">{$t("Entertainment service in our jeans atelier")}</div>
                    <div>
                        {$t("We have a special jeans workshop in this shop. here you can have your newly bought jeans entertained in length for free. you can also go here to have your jeans repaired.")}
                    </div>
                </div>)}
            {hasCoffee && (<div className="mt-5">
                    <div className="font-bold">{$t("Coffee corner")}</div>
                    <div>
                        {$t("Do you want a nice cup of coffee while shopping? while you are looking for a new jeans or outfit, you can use our special coffee corner, where you can enjoy the most delicious coffee, cappuccino or espresso.")}
                    </div>
                </div>)}
        </div>);
};
