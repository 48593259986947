import { Link, RichTextBase } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { PrismicRichText } from "@prismicio/react";
export const JCRichText = RichTextBase({ paragraphDefaultCss: "text-base" });
export const RichText = ({ field }) => (<PrismicRichText field={field} components={{
        heading1: ({ children }) => (<h1 className="text-base leading-tight tracking-0.03 text-grey-default">{children}</h1>),
        heading2: ({ children }) => (<h2 className="text-base leading-tight tracking-0.03 text-grey-default">{children}</h2>),
        heading3: ({ children }) => (<h3 className="text-base leading-tight tracking-0.03 text-grey-default">{children}</h3>),
        heading4: ({ children }) => (<h4 className="text-base leading-tight tracking-0.03 text-grey-default">{children}</h4>),
        heading5: ({ children }) => (<h5 className="text-sm leading-tight tracking-0.03 text-grey-default">{children}</h5>),
        paragraph: ({ children, node, text }) => {
            if (!node.spans.length && !text)
                return (<p>
                            <br />
                        </p>);
            return <p className="text-xs leading-tight tracking-0.03 text-grey-default">{children}</p>;
        },
        hyperlink: ({ children, node }) => {
            if (node.data.url) {
                node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
            }
            const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
            return (<Link href={node.data.url} className="underline" target={target}>
                        {children}
                    </Link>);
        },
        list: ({ children }) => (<ul className="ml-5 text-xs leading-tight tracking-0.03 text-grey-default">{children}</ul>),
        listItem: ({ children }) => (<li className="mb-4 list-disc text-xs leading-tight tracking-0.03 text-grey-default last-of-type:mb-0">
                    {children}
                </li>),
    }}/>);
