import { asText } from "@prismicio/helpers";
import { retry, typedBoolean } from "../helper";
export const log = (type) => {
    console.log("");
    console.log("");
    console.log("*********************************************");
    console.log(`***** HOLD ON - Fetch ${type} data *****`);
    console.log("*********************************************");
    console.log("");
    console.log("");
};
export function getSSGPathData(path, locale, paths = {}) {
    const _path = Array.isArray(path) ? path.join("/") : path.split("/").filter(typedBoolean).join("/");
    return {
        params: { slug: (Array.isArray(path) ? path : path.split("/")).filter(typedBoolean) },
        path: _path,
        paths,
        locale,
    };
}
export const getPrismicPages = (locale, document, paths = {}) => {
    if (document.url) {
        const path = asText(document.url);
        const pathFromPrismic = path[0] == "/" ? path.slice(1) : path;
        if (!pathFromPrismic) {
            console.warn(`The Prismic document with type '${document._meta.type}' and id '${document._meta.id}' has no valid url set - '${path}' is invalid. No page will be generated.`);
            return null;
        }
        else {
            return {
                ...getSSGPathData(pathFromPrismic, locale, paths),
                lastModifiedAt: "",
                deps: {
                    uid: document._meta.uid,
                },
            };
        }
    }
};
export const RecursionGenPages = async (client, { locale, after = "", query }) => {
    const { pageInfo: { endCursor, hasNextPage }, edges, } = await retry(() => client
        .request(query, {
        lang: locale.toLowerCase(),
        after,
    })
        .then((res) => res.content));
    const documents = edges.map((i) => i.node);
    if (hasNextPage) {
        return [...documents, ...(await RecursionGenPages(client, { locale, after: endCursor, query }))];
    }
    return documents;
};
