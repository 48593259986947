import dayjs from "dayjs";
import fs from "fs";
import path from "path";
import { create } from "xmlbuilder2";
import { createGzip } from "zlib";
import { getDomain, typedBoolean } from "../helper";
const safePath = (path) => {
    if (!path)
        return "/";
    if (!path.length)
        return "/";
    return `/${path.filter(typedBoolean).join("/").replace(/\/\/*/g, "/")}`;
};
export const getPageLink = (path) => `${getDomain()}${safePath([path])}`;
const getLastModifiedAt = (item) => {
    switch (item.type) {
        case "category":
        case "product":
            return item.lastModifiedAt;
        default:
            return "";
    }
};
const getImage = (item) => {
    switch (item.type) {
        case "product":
            return item.extra.image;
        default:
            return;
    }
};
export const transformCategorySoftPageSitemap = (pages) => {
    const locale = process.env.NEXT_PUBLIC_LOCALE;
    return pages
        .filter((page) => page[locale])
        .map((page) => ({
        url: getPageLink(page[locale]),
        changefreq: "daily",
        priority: 0.7,
        lastmod: "",
    }))
        .filter((item) => item.url);
};
const formatDate = (dateStr) => (dateStr ? dayjs(dateStr).format("YYYY-MM-DD") : "");
export const transformSitemapData = (data) => data
    .map((item) => ({
    url: getPageLink(item.path),
    changefreq: "daily",
    priority: 0.7,
    lastmod: formatDate(getLastModifiedAt(item) || ""),
    image: getImage(item),
}))
    .filter((item) => item.url);
const siteUrl = getDomain();
const xmlns = "http://www.sitemaps.org/schemas/sitemap/0.9";
const xmlnsNews = "http://www.google.com/schemas/sitemap-news/0.9";
const xmlnsHtml = "http://www.w3.org/1999/xhtml";
const xmlnsMobile = "http://www.google.com/schemas/sitemap-mobile/1.0";
const xmlnsImage = "http://www.google.com/schemas/sitemap-image/1.1";
const xmlnsVideo = "http://www.google.com/schemas/sitemap-video/1.1";
const mapping = {
    category: "sitemap-categories",
    product: "sitemap-products",
    categorySoft: "sitemap-categories-soft",
    marketing: "sitemap-marketing",
};
const writeFileByPath = (data, filePath, cb) => {
    fs.writeFileSync(path.resolve() + filePath, data);
    cb && cb();
};
const writeGunZipFile = (filePath) => {
    const _filePath = path.resolve() + filePath;
    const gzFilePath = path.resolve() + `${filePath}.gz`;
    const gzip = createGzip();
    const source = fs.createReadStream(_filePath);
    const destination = fs.createWriteStream(gzFilePath);
    source.pipe(gzip).pipe(destination);
};
const createSitemap = () => {
    const root = create({ version: "1.0", encoding: "UTF-8" })
        .ele("sitemapindex", { xmlns })
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-products.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-categories.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-categories-soft.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-marketing.xml.gz`)
        .up()
        .up();
    const xml = root.end({ prettyPrint: true });
    writeFileByPath(xml, "/public/sitemap.xml");
};
const createSitemapDetail = (sitemapPages) => {
    Object.entries(sitemapPages).forEach(([key, pages]) => {
        const root = create({ version: "1.0", encoding: "UTF-8" }).ele("urlset", {
            xmlns,
            "xmlns:news": xmlnsNews,
            "xmlns:xhtml": xmlnsHtml,
            "xmlns:mobile": xmlnsMobile,
            "xmlns:image": xmlnsImage,
            "xmlns:video": xmlnsVideo,
        });
        pages.forEach((page) => {
            const item = root
                .ele("url")
                .ele("loc")
                .txt(page.url.toLowerCase())
                .up()
                .ele("changefreq")
                .txt(page.changefreq)
                .up()
                .ele("priority")
                .txt(String(page.priority))
                .up();
            page.lastmod && item.ele("lastmod").txt(page.lastmod).up();
            page.image && item.ele("image:image").ele("image:loc").txt(page.image).up();
            // page.links?.forEach((link) => {
            //     item.ele("xhtml:link", {
            //         rel: "alternate",
            //         hreflang: link.lang,
            //         href: link.url.toLowerCase(),
            //     }).up()
            // })
        });
        const xml = root.end({ prettyPrint: true });
        const filePath = `/public/${mapping[key]}.xml`;
        writeFileByPath(xml, filePath, () => writeGunZipFile(filePath));
    });
};
export const generateSitemap = (sitemapPages) => {
    createSitemap();
    createSitemapDetail(sitemapPages);
};
