import { gql } from "graphql-request";
import { graphqlClient } from "../commercetools";
import { getGtmIdFromCT } from "../helper";
export const orders = gql `
    query ($where: String!) {
        orders(where: $where) {
            results {
                id
                orderNumber
                paymentInfo {
                    payments {
                        paymentMethodInfo {
                            method
                        }
                    }
                }
                lineItems {
                    nameAllLocales {
                        value
                        locale
                    }
                    variant {
                        id
                        sku
                        images {
                            url
                            label
                        }
                        attributesRaw(includeNames: ["size", "length", "waist", "articlename", "colorcode"]) {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;
export const getCtOrders = async (orderNumbers) => {
    const request = {
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: orders,
            variables: {
                where: `orderNumber in (${orderNumbers.map((i) => i.replace(/^0*/, ""))})`,
            },
        },
    };
    const data = await graphqlClient.execute(request).then((res) => {
        return res.body.data.orders.results;
    });
    return data.reduce((pre, cur) => Object.assign(pre, {
        [cur.orderNumber]: {
            lineItems: cur.lineItems.reduce((_pre, _cur) => {
                var _a, _b, _c, _d;
                return Object.assign(_pre, {
                    [_cur.variant.sku]: {
                        name: _cur.nameAllLocales.reduce((pre, cur) => Object.assign(pre, { [cur.locale]: cur.value }), {}),
                        thumbnail: ((_a = _cur.variant.images.find((image) => image.label === "thumbnail" || image.label === "onmodel-front-500")) === null || _a === void 0 ? void 0 : _a.url) || "",
                        size: ((_b = _cur.variant.attributesRaw.find((i) => i.name === "size")) === null || _b === void 0 ? void 0 : _b.value) || "",
                        length: ((_c = _cur.variant.attributesRaw.find((i) => i.name === "length")) === null || _c === void 0 ? void 0 : _c.value) || "",
                        waist: ((_d = _cur.variant.attributesRaw.find((i) => i.name === "waist")) === null || _d === void 0 ? void 0 : _d.value) || "",
                        productId: getGtmIdFromCT(_cur.variant.attributesRaw),
                    },
                });
            }, {}),
            paymentsInfo: cur.paymentInfo.payments.map((i) => i.paymentMethodInfo.method),
        },
    }), {});
};
