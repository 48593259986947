import { gtmPush } from ".";
export const gtmAddToWishlist = (products) => {
    gtmPush({
        event: "addToWishlist",
        ecommerce: { wishlist: { add: { products } } },
    });
};
export const gtmRemoveFromWishlist = (products) => {
    gtmPush({
        event: "removeFromWishlist",
        ecommerce: { wishlist: { remove: { products } } },
    });
};
export const gtmWishlistView = (products) => {
    gtmPush({
        event: "wishlistView",
        ecommerce: { wishlist: { view: { products } } },
    });
};
export const gtmAddToCartFromWishlist = (products) => {
    gtmPush({
        event: "addToCartFromWishlist",
        ecommerce: { wishlist: { addToCart: { products } } },
    });
};
