import { gql } from "graphql-request";
export const totalProductsQuery = gql `
    query {
        products(limit: 1, where: "masterData(published = true)") {
            total
        }
    }
`;
export const productsQuery = gql `
    query ($limit: Int, $offset: Int) {
        products(limit: $limit, offset: $offset, where: "masterData(published = true)") {
            total
            results {
                id
                lastModifiedAt
                masterData {
                    current {
                        masterVariant {
                            images {
                                url
                                label
                            }
                        }
                        nameAllLocales {
                            locale
                            value
                        }
                        slugAllLocales {
                            locale
                            value
                        }
                        categories {
                            id
                            orderHint
                        }
                    }
                }
            }
        }
    }
`;
export const productQuery = gql `
    query ($id: String!, $locale1: Locale!, $locale2: Locale!) {
        product(id: $id) {
            id
            lastModifiedAt
            masterData {
                published
                current {
                    name(locale: $locale1)
                    slug(locale: $locale1)
                    description(locale: $locale2)
                    categories {
                        id
                    }
                    masterVariant {
                        images {
                            url
                            label
                        }
                        attributesRaw {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;
