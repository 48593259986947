import Script from "next/script";
import { createElement, memo, useEffect, useState } from "react";
export const TrustedShopScript = memo(function TrustedShop() {
    const [mounted, isMounted] = useState(false);
    useEffect(() => {
        isMounted(true);
    }, []);
    if (mounted && typeof window !== "undefined") {
        return <Script defer async id="trustedShop" src="https://integrations.etrusted.com/applications/widget.js/v2"/>;
    }
    return <></>;
});
export const TrustedShopWidget = memo(function Widget({ className, widgetId, }) {
    return (<div className={className}>
            {createElement("etrusted-widget", {
            id: "trustedShop",
            "data-etrusted-widget-id": widgetId || process.env.NEXT_PUBLIC_ETRUSTED_WIDGET,
        })}
        </div>);
});
