import styled from "@emotion/styled";
export const BillingFormWrapper = styled.div `
    label {
        align-self: center;
    }
    .Error {
        text-align: left;
        white-space: nowrap;
        margin-bottom: 0;
    }
`;
