import { ErrorBoundary, Head } from "@/components";
import { Container, Link, useI18n } from "@jog/react-components";
import { DUTCH_WEEK, getDomain } from "@jog/shared";
import { useMemo } from "react";
import { ShopInfo } from "./ShopInfo";
const getOpeningHoursSpecification = (openings) => DUTCH_WEEK.map((dayOfWeek) => {
    const [opens, closes] = openings[dayOfWeek].split("-").map((i) => i.trim());
    return { dayOfWeek, opens, closes };
}).reduce((pre, cur) => {
    //exclude remarks key from the array and exclude days without opening and closing hours
    if (!cur.opens || !cur.closes)
        return pre;
    const daysSameTime = pre.find((item) => item.opens === cur.opens && item.closes === cur.closes);
    if (!daysSameTime) {
        pre.push({ ...cur, dayOfWeek: [cur.dayOfWeek], "@type": "OpeningHoursSpecification" });
        return [...pre];
    }
    daysSameTime.dayOfWeek.push(cur.dayOfWeek);
    return [...pre];
}, []);
export const JcStore = ({ store }) => {
    const { $t } = useI18n();
    const storeTitle = useMemo(() => `Jeans Centre ${store.town} | ${$t("Opening hours & information")}`, [$t, store.town]);
    const storeJson = useMemo(() => {
        const domain = getDomain();
        const info = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "@id": `${domain}/#LocalBusiness`,
            name: storeTitle,
            address: {
                "@type": "PostalAddress",
                streetAddress: store.address || "",
                addressLocality: store.town || "",
                addressRegion: store.storeNumber || "",
                postalCode: store.postalCode || "",
                addressCountry: "NL",
            },
            geo: {
                "@type": "GeoCoordinates",
                latitude: store.latitude || "",
                longitude: store.longitude || "",
            },
            telephone: store.phone || "",
            openingHoursSpecification: getOpeningHoursSpecification(store.openings),
            image: store.image || "",
            url: `${domain}/winkels/${store.slug}`,
        };
        return JSON.stringify(info);
    }, [store, storeTitle]);
    return (<ErrorBoundary system="store">
            <Head title={storeTitle} description={`${$t("Looking for information about Jeans Center establishment")} ${store.town}? ${$t("View the opening hours, address and contact details here.")}`}>
                {storeJson && <script type="application/ld+json">{storeJson}</script>}
            </Head>
            <Container>
                <div className="bg-white py-5 px-4 lg:px-10">
                    <ShopInfo store={store} imageSize="lg"/>
                    <Link href="/winkels" className="my-8 inline-block border-solid bg-green-main p-1.5 px-5 font-primary text-base font-bold uppercase text-white hover:bg-orange-main focus:outline-none lg:py-2.5">
                        {$t("Find a store")}
                    </Link>
                </div>
            </Container>
        </ErrorBoundary>);
};
