import { makeAutoObservable } from "mobx";
export class VoucherStore {
    constructor(options) {
        this.options = options;
        this.voucherErrorMessage = false;
        this.isVoucherCtaDisabled = false;
        this.voucherData = null;
        this.userVouchers = null;
        this.voucherDisclaimer = null;
        makeAutoObservable(this);
    }
    async applyVoucher(voucher, cartId) {
        this.isVoucherCtaDisabled = true;
        try {
            const response = await this.options.api.voucherApply(voucher, cartId);
            if (response && response.statusCode === 200) {
                await this.options.store.cartStore.getCartView(response.body.cart.id);
                this.voucherErrorMessage = false;
                // Get voucher data need to check if voucher value is more than the total price
                if (this.options.skipVoucherCheck)
                    return;
                const voucherResponse = await this.options.api.getVoucher(voucher);
                if (voucherResponse && voucherResponse.statusCode === 200) {
                    this.voucherData = voucherResponse.body.voucher;
                }
            }
            else {
                this.voucherErrorMessage = true;
                await this.removeVoucher(voucher, cartId);
            }
        }
        catch (e) {
            console.log(e);
            this.voucherErrorMessage = true;
        }
        this.isVoucherCtaDisabled = false;
    }
    async removeVoucher(discountCodeId, cartId) {
        this.isVoucherCtaDisabled = true;
        try {
            const response = await this.options.api.voucherRemove(discountCodeId, cartId);
            if (response && response.statusCode === 200) {
                await this.options.store.cartStore.getCartView(response.body.cart.id);
            }
        }
        catch (e) {
            console.log(e);
        }
        this.isVoucherCtaDisabled = false;
    }
    async getUserVouchers(loyaltyCardNumber) {
        try {
            const userVouchers = await this.options.api.getUserVouchers(loyaltyCardNumber);
            if (userVouchers) {
                this.userVouchers = userVouchers.body;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async getPrismicVoucherDisclaimer() {
        if (this.options.prismic.getVoucherDisclaimer) {
            try {
                this.voucherDisclaimer = await this.options.prismic.getVoucherDisclaimer();
                return this.voucherDisclaimer;
            }
            catch (e) {
                console.log(e);
            }
        }
    }
}
