export const LocationIcon = (props) => (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={24} height={24} viewBox="0 0 41 52" xmlSpace="preserve" {...props}>
        <g>
            <defs>
                <rect x="3.94" y="2.95" width="33.74" height="45.6"/>
            </defs>
            <clipPath>
                <use xlinkHref="#SVGID_1_"/>
            </clipPath>
            <path d="M20.67,46.04
		c0.04-0.03,0.08-0.07,0.12-0.1c0.09-0.07,0.19-0.14,0.28-0.21c0.11-0.09,0.22-0.18,0.33-0.28c3.96-3.92,7.5-8.17,10.26-13.04
		c1.86-3.28,3.28-6.71,3.74-10.5c0.3-2.47,0.06-4.88-0.82-7.21c-2.53-6.71-9.44-10.63-16.44-9.33c-3.83,0.71-6.87,2.71-9.17,5.8
		c-2.78,3.72-3.35,7.94-2.48,12.4c0.61,3.14,1.89,6.03,3.46,8.79c2.71,4.77,6.17,8.96,10.02,12.83
		C20.23,45.45,20.44,45.75,20.67,46.04 M37.69,19.65c-0.09,3.47-0.79,6.4-1.94,9.24c-1.65,4.08-4,7.75-6.67,11.21
		c-2.08,2.69-4.38,5.19-6.86,7.51c-0.31,0.29-0.65,0.53-0.98,0.79c-0.27,0.21-0.56,0.23-0.83,0c-0.51-0.43-1.04-0.85-1.52-1.31
		c-4.1-3.97-7.73-8.32-10.59-13.29c-1.66-2.89-3.05-5.9-3.75-9.19c-0.39-1.79-0.7-3.59-0.58-5.43c0.27-3.97,1.59-7.52,4.29-10.52
		c2.72-3.04,6.1-4.84,10.12-5.47c0.77-0.12,1.56-0.26,2.34-0.24c4.04,0.09,7.68,1.32,10.81,3.92c3.16,2.62,5.11,5.96,5.83,10
		C37.55,17.92,37.61,18.98,37.69,19.65"/>
            <path d="M20.84,14.43
		c-2.95,0-5.39,2.43-5.4,5.39c-0.01,2.99,2.43,5.46,5.38,5.46c3,0,5.42-2.47,5.36-5.62C26.12,16.64,23.57,14.4,20.84,14.43
		 M20.83,12.3c4.16,0,7.6,3.48,7.47,7.76c-0.12,4.12-3.54,7.39-7.54,7.34c-4.15-0.06-7.46-3.42-7.43-7.6
		C13.37,15.63,16.72,12.28,20.83,12.3"/>
        </g>
    </svg>);
