import { handleCartError } from "../helper";
export const setPaymentCostBase = ({ client }) => async (cartId, version, paymentName, centAmount) => {
    const request = (version) => client.execute({
        uri: `/ctp/carts/${cartId}`,
        method: "POST",
        body: {
            version,
            actions: [
                {
                    action: "addCustomLineItem",
                    name: { en: paymentName, "nl-nl": paymentName },
                    quantity: 1,
                    money: { currencyCode: "EUR", centAmount },
                    slug: paymentName.toLowerCase(),
                    taxCategory: { typeId: "tax-category", key: "vat" },
                },
            ],
        },
    });
    return handleCartError(request, version, 5);
};
