import { isExpired, parseVoucherTags } from "@jog/shared";
import { styled } from "@jog/theming";
import { Link } from "../index";
const VoucherCardStyles = styled.div `
    min-width: 260px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: white;
    padding: ${(props) => props.theme.padding.standard};
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
    text-decoration: none;
    color: #5c616b;

    a.more-info div {
        text-transform: uppercase;
        padding-top: ${(props) => props.theme.padding.standard};
        padding-bottom: ${(props) => props.theme.padding.large};
        color: ${(props) => props.moreInfoColor};
    }

    h2 {
        font-family: ${(props) => props.theme.fontFamily.primary};
        font-weight: normal;
    }

    .redeemed h2 {
        text-decoration: line-through;
    }

    picture {
        img {
            margin-bottom: ${(props) => props.theme.padding.large};
            width: 100%;
            height: auto;
        }
    }

    .redeemed {
        cursor: pointer;
        background: white;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    .redeemed-text {
        padding-top: ${(props) => props.theme.padding.standard};
        font-size: ${(props) => props.theme.fontSize.label};
        font-family: ${(props) => props.theme.fontFamily.primary};
        font-weight: bold;
        text-transform: uppercase;
    }

    .expiration-date {
        font-size: 10px;
        text-transform: uppercase;
        color: ${(props) => props.expirationDateColor || "#bfbfbf"};
        align-self: flex-end;
        margin-bottom: 0;
        padding-top: 15px;
    }
`;
export const VoucherCardBase = ({ ArrowText, moreInfoColor }) => {
    const VoucherCardFC = ({ voucherData, expiresOn, expirationDateColor, redeemed }) => {
        const voucherLink = `/my-account/vouchers/voucher-details?voucherCode=${voucherData.voucherCode}`;
        const voucherTitle = parseVoucherTags(voucherData, voucherData.title);
        const isVoucherExpired = isExpired(voucherData.expirationDate);
        return (<VoucherCardStyles moreInfoColor={moreInfoColor} expirationDateColor={expirationDateColor} id={voucherData.voucherCode}>
                <div className={voucherData.isRedeemed || isVoucherExpired ? "redeemed" : ""}>
                    <picture>
                        <source srcSet={voucherData.desktopImageUrl || undefined} media="(min-width: 481px)"/>
                        <img src={voucherData.imageUrl || undefined} alt={voucherData.title}/>
                    </picture>
                    <h2>{voucherTitle}</h2>
                    <p className="expiration-date">
                        {expiresOn} {voucherData.expirationDate}
                    </p>
                    {voucherData.isRedeemed && <p className="redeemed-text"> {redeemed}</p>}
                    <Link to={voucherLink} className="more-info">
                        <ArrowText marginBot="0" text="More info"/>
                    </Link>
                </div>
            </VoucherCardStyles>);
    };
    return VoucherCardFC;
};
