import { styled } from "@jog/theming";
export const Close = styled.span `
    color: ${(props) => props.color || "#fff"};
    background-color: ${(props) => props.bgColor || "transparent"};
    padding: ${(props) => props.padding || "8px 14px"};
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: ${(props) => props.hoverBgColor || props.theme.color.primaryHover};
    }
    &:before {
        font-family: ${(props) => props.theme.fontFamily.icomoonJC};
        font-size: ${(props) => props.fontSize || "17px"};
        font-weight: 400;
        content: "\\e902";
    }
`;
