import pdpJson from "@/lib/prismicio/prismic-pdp.json";
import { usePrefetch } from "@jog/react-components";
import { prismicClient, retry, transPdp } from "@jog/shared";
export function prefetchSimplePdp(prefetch) {
    return prefetch("pdp", async (pageContext) => {
        const { desktopSortingByName, mobileSortingByName, faslet } = await retry(() => (pageContext === null || pageContext === void 0 ? void 0 : pageContext.previewData)
            ? prismicClient({ previewData: pageContext.previewData })
                .getSingle("pdp")
                .then((res) => transPdp(res.data))
            : Promise.resolve(pdpJson));
        return { desktopSortingByName, mobileSortingByName, faslet };
    });
}
export const useSimplePdp = () => {
    const { data } = usePrefetch("pdp");
    return data;
};
