import classNames from "classnames";
import { useI18n } from "../../hooks";
export const AccordionTitleBase = ({ TitleFC, spanCss, activeClass }) => {
    const AccordionTitle = ({ number, title, isActive, isStepCompleted, changeExactActiveStep, }) => {
        const { $t } = useI18n();
        return (<div className={classNames("flex items-center uppercase", isActive ? activeClass : "", isStepCompleted ? "pointer-events-auto" : "pointer-events-none")}>
                <>
                    <TitleFC className="m-0 self-center" isActive={isActive} isStepCompleted={isStepCompleted} number={number} title={title}/>
                    {isStepCompleted && (<div className="backWrapper flex-1 text-right">
                            <span className={classNames("cursor-pointer bg-transparent text-xs", spanCss)} onClick={() => {
                    changeExactActiveStep(number);
                }}>
                                {$t("change")}
                            </span>
                        </div>)}
                </>
            </div>);
    };
    return AccordionTitle;
};
