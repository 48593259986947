import { handleCartError } from "../helper";
export const recalculateCartBase = ({ client }) => async (cartId, version) => {
    const request = (version) => client.execute({
        method: "POST",
        uri: `/ctp/carts/${cartId}`,
        body: {
            version,
            actions: [
                {
                    action: "recalculate",
                    updateProductData: true,
                },
            ],
        },
        // method: "PUT",
        // uri: `/ctp/carts/view/${cartId}`
    });
    return handleCartError(request, version, 5);
};
