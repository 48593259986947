import { getDomain } from "@jog/shared";
import NextHead from "next/head";
export const BreadcrumbListHead = ({ list }) => (<NextHead>
        <script type="application/ld+json">
            {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: list.map((item, index) => ({
            "@type": "ListItem",
            position: index + 1,
            name: item.text,
            item: `${getDomain()}${item.href}`,
        })),
    })}
        </script>
    </NextHead>);
