import { replacePlaceholderInUrl } from "@jog/shared";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import classNames from "classnames";
import { NextImage } from "../../components/NextImage/NextImage";
export const RichTextBase = ({ paragraphDefaultCss }) => {
    const RichText = ({ field, h1Class, h2Class, h3Class, h4Class, h5Class, h6Class, paragraphClass, paragraphStyle, emClass, linkClass, linkTarget, linkStyle, }) => {
        return (<PrismicRichText field={field} components={{
                heading1: ({ children }) => (<h1 className={classNames(h1Class ? h1Class : "text-[26px] leading-9")}>{children}</h1>),
                heading2: ({ children }) => (<h2 className={classNames(h2Class ? h2Class : "text-22 leading-7")}>{children}</h2>),
                heading3: ({ children }) => (<h3 className={classNames(h3Class ? h3Class : "text-lg")}>{children}</h3>),
                heading4: ({ children }) => (<h4 className={classNames(h4Class ? h4Class : "text-base")}>{children}</h4>),
                heading5: ({ children }) => (<h5 className={classNames(h5Class ? h5Class : "text-sm")}>{children}</h5>),
                heading6: ({ children }) => (<h6 className={classNames(h6Class ? h6Class : "text-xs")}>{children}</h6>),
                paragraph: ({ children, node, text }) => {
                    if (!node.spans.length && !text) {
                        return (<p>
                                    <br />
                                </p>);
                    }
                    return (<p className={classNames(paragraphClass ? paragraphClass : `${paragraphDefaultCss} mb-2.5`)} style={paragraphStyle}>
                                {children}
                            </p>);
                },
                list: ({ children }) => <ul className="list-disc pl-10">{children}</ul>,
                oList: ({ children }) => <ol className="list-decimal pl-10">{children}</ol>,
                hyperlink: ({ node, children }) => {
                    if (node.data.url) {
                        node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                        node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                        node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
                    }
                    const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
                    return (<PrismicLink field={node.data} target={linkTarget || target} className={classNames(linkClass ? linkClass : "text-black underline")} style={linkStyle}>
                                {children}
                            </PrismicLink>);
                },
                em: ({ children }) => <em className={emClass}>{children}</em>,
                image: ({ node }) => (<p className="overflow-x-auto">
                            <NextImage className="max-w-none" width={node.dimensions.width} height={node.dimensions.height} src={node.url} alt={node.alt || ""}/>
                        </p>),
            }}/>);
    };
    return RichText;
};
