import { AWSClient } from "./index";
export const loyaltyClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_LOYALTY_ENDPOINT || "");
export const getGiftcard = (giftcardCode) => loyaltyClient()({
    uri: `/giftcards/${giftcardCode}`,
    method: "GET",
});
export const getUserVouchers = (loyaltyCardNumber) => loyaltyClient()({
    uri: `/membership/${loyaltyCardNumber}/vouchers`,
    method: "GET",
});
export const getVoucher = async (voucherCode) => loyaltyClient()({
    uri: `/vouchers/${voucherCode}`,
    method: "GET",
});
export const getMemberJcPoints = async (cardNumber) => loyaltyClient()({
    method: "GET",
    uri: `/membership/${cardNumber}/points`,
});
