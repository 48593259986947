import { gtmPush } from "./index";
export const gtmNewsletterSubscription = () => {
    gtmPush({ event: "newsletter_subscription", formLocation: "widget_homepage" });
};
export const gtmPageTracking = (gtmTypeOfPage) => {
    gtmPush({ ecomm_pagetype: gtmTypeOfPage || "other" });
};
export const gtmAddUserId = (userId) => {
    userId && gtmPush({ "user-id": userId });
};
