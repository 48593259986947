import { Redis } from "@upstash/redis";
import dayjs from "dayjs";
import chunk from "lodash/chunk";
import { retry, sleep } from "../helper";
let redis;
const CACHE_UPDATE_TIME = `CACHE_UPDATE_TIME`;
const REDIS_CACHE_STRATEGY = process.env.REDIS_CACHE_STRATEGY || "normal";
export var NotFoundType;
(function (NotFoundType) {
    NotFoundType[NotFoundType["FETCH_REDIS_FAIL"] = 0] = "FETCH_REDIS_FAIL";
    NotFoundType[NotFoundType["WRONG_PAGE_TYPE"] = 1] = "WRONG_PAGE_TYPE";
    NotFoundType[NotFoundType["FETCH_DATA_FAIL"] = 2] = "FETCH_DATA_FAIL";
})(NotFoundType || (NotFoundType = {}));
export class RedisCache {
    constructor(site = process.env.NEXT_PUBLIC_ACTIVE_COUNTRY || "") {
        this.site = site;
        this.cacheUpdateTime = new Date();
        this.CACHE_DATA_STATUS = ``;
        this.updateRedisStatus = async (type) => {
            if (type === "pending") {
                this.cacheUpdateTime = new Date();
                console.log(`Pending at: ${this.cacheUpdateTime.getTime()}`);
            }
            await retry(() => this.redis.set(this.CACHE_DATA_STATUS, type));
            await retry(() => this.redis.expire(this.CACHE_DATA_STATUS, 60 * 30));
            if (type === "done") {
                await retry(() => this.redis.hset(CACHE_UPDATE_TIME, { [this.site]: `${this.cacheUpdateTime.getTime()}` }));
            }
        };
        this.checkRedisStatus = () => retry(() => this.redis.get(this.CACHE_DATA_STATUS));
        this.ready = async (callback = console.log) => {
            switch (REDIS_CACHE_STRATEGY) {
                case "force": {
                    callback(`Strategy: ${REDIS_CACHE_STRATEGY}`);
                    callback(`Force write cache data`);
                    return true;
                }
                case "skip": {
                    callback(`Strategy: ${REDIS_CACHE_STRATEGY}`);
                    callback(`Skip write cache data`);
                    return false;
                }
                default: {
                    const status = await this.checkRedisStatus().catch(() => "pending");
                    if (status === "pending") {
                        callback(`Strategy: ${REDIS_CACHE_STRATEGY}`);
                        callback(`RedisStatus: ${status}`);
                        callback("Skip write cache data");
                        return false;
                    }
                    return true;
                }
            }
        };
        this.write = async (pages) => {
            const shouldWriteRedis = await this.ready();
            if (shouldWriteRedis) {
                await this.updateRedisStatus("pending");
                await this.setAll(pages);
                await this.updateRedisStatus("done");
            }
        };
        this.failArr = [];
        this.setAll = async (pages, callback = console.log) => {
            this.failArr = [];
            const total = pages.length;
            const perPage = 100;
            const totalPages = Math.ceil(total / perPage);
            callback(`Total items: ${total}`);
            callback(`Total chunks: ${totalPages}`);
            const pagesChunk = chunk(pages, perPage);
            for (let group = 0; group < pagesChunk.length; group++) {
                await Promise.all(pagesChunk[group].map((page) => this.set(page)));
                await (callback === null || callback === void 0 ? void 0 : callback(`(${group + 1}/${pagesChunk.length}) Group cache set`));
                await sleep(1000);
            }
            await this.reSet(callback);
            await (callback === null || callback === void 0 ? void 0 : callback(`Write ${pages.length} pages to redis success`));
        };
        this.reSet = async (callback = console.log) => {
            if (this.failArr.length) {
                const failArr = [...this.failArr];
                this.failArr = [];
                const pagesChunk = chunk(failArr, 100);
                for (let group = 0; group < pagesChunk.length; group++) {
                    await Promise.all(pagesChunk[group].map((page) => this.set(page)));
                    await (callback === null || callback === void 0 ? void 0 : callback(`[ReSet] (${group + 1}/${pagesChunk.length}) Group cache set`));
                    await sleep(1000);
                }
                await this.reSet(callback);
            }
        };
        this.set = async (page) => {
            try {
                const pagePath = page.path.startsWith("/") ? page.path.slice(1) : page.path;
                const path = `${page.locale}:${pagePath}`;
                page.redisUpdatedAt = this.cacheUpdateTime.getTime();
                await this.redis.set(path, page);
                return page;
            }
            catch (error) {
                console.error(error);
                this.failArr.push(page);
                return null;
            }
        };
        this.fetch = async (urlPath, locale) => {
            const path = `${locale}:${urlPath}`;
            const [data, CacheUpdateTime] = await Promise.all([
                retry(() => this.redis.get(`${path}`)),
                retry(() => this.redis.hget(CACHE_UPDATE_TIME, this.site)),
            ]);
            if (!(data === null || data === void 0 ? void 0 : data.redisUpdatedAt))
                return undefined;
            if (!CacheUpdateTime)
                return undefined;
            if (+data.redisUpdatedAt < +CacheUpdateTime)
                return undefined;
            return data || undefined;
        };
        this.writeNotFound = async (locale, path, type) => {
            await retry(async () => {
                const date = dayjs();
                await this.redis.lpush(`404:${locale}:${path}:${date.format("YYYY-MM-DD")}`, {
                    date: date.toDate().getTime(),
                    type,
                });
            });
        };
        redis = redis || Redis.fromEnv();
        this.redis = redis;
        this.CACHE_DATA_STATUS = `${site}:CACHE_DATA_STATUS`;
    }
}
const devLog = {};
export const redisLog = async (type, value) => {
    if (!process.env.UPSTASH_REDIS_COUNT_PRISMIC) {
        return;
    }
    const day = dayjs().format("YYYY-MM-DD");
    const key = `${type}:${day}:${encodeURIComponent(value)}`;
    if (process.env.NODE_ENV === "production") {
        const redis = Redis.fromEnv();
        await redis.incr(key);
    }
    else {
        devLog[key] = (devLog[key] || 0) + 1;
        console.log(key, devLog[key]);
    }
};
