import React, { Fragment, useState } from "react";
export const TextDropdownBase = ({ ArrowText }) => {
    const TextDropdownFC = ({ text, isLeftArrow, marginBot, children, displayStatus = "none", }) => {
        const [childrenVisibility, setChildrenVisibility] = useState(displayStatus);
        const [isArrowDirectionLeft, setIsArrowDirectionLeft] = useState(isLeftArrow);
        const toggleVisibility = () => {
            const visibility = childrenVisibility === "none" ? "block" : "none";
            setChildrenVisibility(visibility);
            setIsArrowDirectionLeft(!isArrowDirectionLeft);
        };
        return (<Fragment>
                <ArrowText isLeftArrow={isArrowDirectionLeft} marginBot={marginBot} toggleVisibility={toggleVisibility} text={text}/>
                <div className="mb-2.5" style={{ display: childrenVisibility }}>
                    {children}
                </div>
            </Fragment>);
    };
    return TextDropdownFC;
};
