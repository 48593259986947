import { getUserVouchers } from "../aws/loyalty";
export const getUserVouchersBase = ({ setToken, execute }) => {
    return async (loyaltyCardNumber) => {
        return process.env.NEXT_PUBLIC_USE_RB2_MEMBERSHIP === "yes"
            ? getUserVouchers(loyaltyCardNumber)
            : execute(await setToken({
                uri: `/loyalty/v1/membership/${loyaltyCardNumber}/vouchers`,
                method: "GET",
            }));
    };
};
