import { getGtmIdFromTW } from "../helper";
import { getImpressions, gtmPush } from "./index";
export const gtmPushAllListerProducts = (items, category) => {
    if (!items.length || !category)
        return;
    const impressions = getImpressions(items, () => category);
    gtmPush({ event: "impression-send", ecommerce: { currencyCode: "EUR", impressions } });
};
export const gtmPushAllListerProductsAfterSearch = (items) => {
    if (!items.length)
        return;
    const impressions = getImpressions(items, (product) => { var _a, _b; return ((_b = (_a = product.attributes.find((attribute) => attribute.name === "categoryAsUrl")) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0]) || ""; });
    gtmPush({ event: "impression-send", ecommerce: { currencyCode: "EUR", impressions } });
};
export const gtmProductClickOnLister = (product, category) => {
    if (!product)
        return;
    gtmPush({
        event: "productClick",
        ecommerce: {
            click: {
                actionField: { list: category },
                products: [
                    {
                        id: getGtmIdFromTW(product),
                        name: product.title,
                        price: product.price,
                        brand: product.brand,
                        category,
                        variant: "",
                    },
                ],
            },
        },
    });
};
