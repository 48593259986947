// Reference about responsive images:
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes
import { typedBoolean } from "./helper";
const checkVW = (v) => (v.endsWith("vw") ? v : `${v}px`);
export const imageSizes = ({ sm, md, lg, xl, ["2xl"]: xxl, }) => {
    const values = [];
    if (sm)
        values.push(`(max-width: 576px) ${checkVW(sm)}`);
    if (md)
        values.push(`(max-width: 768px) ${checkVW(md)}`);
    if (lg)
        values.push(`(max-width: 992px) ${checkVW(lg)}`);
    if (xl)
        values.push(`(max-width: 1200px) ${checkVW(xl)}`);
    if (xxl)
        values.push(`(max-width: 1400px) ${checkVW(xxl)}`);
    values.push(checkVW([sm, md, lg, xl, xxl].filter(typedBoolean).at(-1) || "100vw"));
    return values.join(", ");
};
export const containerSlot = (n) => ({
    lg: `${960 / n}`,
    xl: `${1140 / n}`,
    ["2xl"]: `${1400 / n}`,
});
