import { handleCartError } from "../helper";
export const addLoyaltyCardNumberBase = ({ client }) => async (cartId, version, loyaltyCardNumber) => {
    const request = (version) => client.execute({
        uri: `/ctp/carts/${cartId}`,
        method: "POST",
        body: {
            version,
            actions: [
                {
                    action: "setCustomType",
                    type: { key: "loyaltyCardType", typeId: "type" },
                    fields: { loyaltyCardNumber },
                },
            ],
        },
    });
    return handleCartError(request, version, 5);
};
