import Script from "next/script";
const __whatsAppOptions = {
    enabled: true,
    chatButtonSetting: {
        backgroundColor: "#72956C",
        ctaText: "",
        borderRadius: "25",
        marginLeft: "0",
        marginBottom: "30",
        marginRight: "30",
        position: "right",
    },
    brandSetting: {
        brandName: "Jeans Centre",
        brandSubTitle: "Customer Happiness Team",
        brandImg: "https://www.madnic-creative.eu/wp-content/uploads/2023/05/logo-JC.png",
        welcomeText: `Welkom bij onze WhatsApp chat.

Klik op de knop <strong>‘Start WhatsApp’</strong> om contact met ons op te nemen.

<strong>Wij reageren binnen onze openingstijden:</strong>
ma-vrij 10.00-17.00 uur
klik <a target="_blank" rel="noreferrer" style="color:#72956C;text-decoration-line: underline;" href="https://www.jeanscentre.nl/service">hier</a> voor onze actuele openingstijden

<strong>Liever mailen of bellen?</strong><a style="color:#72956C;text-decoration-line: underline;" href="mailto:klantenservice@jeanscentre.nl">klantenservice@jeanscentre.nl</a>
<a style="color:#72956C;text-decoration-line: underline;" href="tel:0850013014">0850013014</a>`,
        backgroundColor: "#72956C",
        ctaText: "Start Whatsapp",
        borderRadius: "25",
        autoShow: false,
        phoneNumber: "31850013014",
    },
};
export const WhatsappScript = () => {
    return (<>
            <a href="https://api.whatsapp.com/send?phone=31850013014" className="floating" target="_blank" rel="noreferrer">
                <i className="fab fa-whatsapp float-button"></i>
            </a>
            <Script async src="https://www.madnic-creative.eu/wp-content/uploads/2023/05/jsonvalidator-1.txt" onLoad={() => {
            var _a;
            window.__whatsAppOptions = __whatsAppOptions;
            (_a = window.CreateWhatsappChatWidget) === null || _a === void 0 ? void 0 : _a.call(window, __whatsAppOptions);
            console.log("Init Whatsapp success");
        }}/>
        </>);
};
