import { Component } from "react";
export class ErrorBoundaryBase extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: false,
        };
    }
    componentDidCatch(error) {
        this.setState({
            error,
        });
    }
    render() {
        const { children, system, errorGeneral, tryAgain } = this.props;
        if (this.state.error) {
            return (<div style={{
                    background: "#f1dede",
                    margin: "10px",
                    padding: "10px",
                    textAlign: "center",
                }}>
                    <h1>
                        {errorGeneral} {system || "system"} <br />
                        {tryAgain}
                    </h1>
                </div>);
        }
        return children;
    }
}
export class LayoutErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: null,
        };
    }
    componentDidCatch(error) {
        this.setState({
            error,
        });
        console.error(error);
    }
    render() {
        const { children } = this.props;
        if (this.state.error) {
            const Fallback = this.props.Fallback;
            return <Fallback error={this.state.error}/>;
        }
        return children;
    }
}
