import classNames from "classnames";
import { Arrow } from "../Arrow/Arrow";
import { Link } from "../Link/Link";
export const ArrowLinkBase = ({ arrowLinkWrapperCss, linkCss, isJC }) => {
    const ArrowLinkFC = ({ marginBot, text, href }) => {
        return (<div className={classNames("group flex items-center", arrowLinkWrapperCss)} style={{ marginBottom: marginBot || "22px" }}>
                <Arrow className={classNames(isJC ? "group-hover:!border-orange-main" : "")}/>
                <Link className={classNames("ml-1.5 cursor-pointer no-underline", linkCss)} to={href}>
                    {text}
                </Link>
            </div>);
    };
    return ArrowLinkFC;
};
