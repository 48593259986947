import { handleCartError } from "../helper";
export const setShippingCostBase = ({ client }) => async (cartId, shippingMethodId, version) => {
    const request = (version) => client.execute({
        uri: `/ctp/carts/${cartId}`,
        method: "POST",
        body: {
            version,
            actions: [
                {
                    action: "setShippingMethod",
                    shippingMethod: { id: shippingMethodId, typeId: "shipping-method" },
                },
            ],
        },
    });
    return handleCartError(request, version, 5);
};
