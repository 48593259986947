export const voucherRemoveBase = ({ execute, setToken }) => {
    return async (voucherCode, cartId) => {
        const requestWithToken = await setToken({
            uri: `/ctp/carts/${cartId}/removeVoucher`,
            method: "POST",
            body: {
                voucherCode,
            },
        });
        return execute(requestWithToken);
    };
};
