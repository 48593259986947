import { useTextConversion } from "@/lib/prismicio/useTextConversion";
import { BreadcrumbListHead, Container, Link } from "@jog/react-components";
import classNames from "classnames";
const Arrow = ({ className }) => (<div className={classNames("relative h-6.5 w-6.5", className)}>
        <span className="absolute left-0 h-full w-2 bg-gray-bg"/>
        <span className="absolute left-2 h-0 w-0 border-13 border-gray-bg border-r-transparent border-t-transparent border-b-transparent"/>
        <span className="absolute left-0 h-0 w-0 border-13 border-r-transparent border-t-transparent border-b-transparent border-l-white"/>
    </div>);
const BreadcrumbItem = ({ text, href }) => {
    const title = useTextConversion(text);
    return (<div className="group relative flex items-center bg-white">
            <Link className="pl-0.5 pr-1.25 uppercase text-gray-main hover:no-underline group-first-of-type:pl-3 group-last-of-type:pointer-events-none group-last-of-type:pr-3 group-last-of-type:text-gray-main" to={href} title={title}>
                {title}
            </Link>
            <Arrow className="group-last-of-type:hidden"/>
        </div>);
};
const home = { text: "Home", href: "/" };
export const Breadcrumbs = ({ displayInMobile, list, }) => (<>
        <BreadcrumbListHead list={[home, ...list]}/>
        <Container className={classNames("lg:block", displayInMobile ? "block" : "hidden")}>
            <div className="flex max-w-full">
                <div className="my-3 mx-2 flex text-xs lg:my-6.75 lg:mx-0">
                    {[home, ...list].map(({ text, href }) => (<BreadcrumbItem href={href} text={text.replace(/-/g, " ")} key={href}/>))}
                </div>
            </div>
        </Container>
    </>);
