import { styled } from "../../index";
export const WhiteBoxBase = ({ defaultPadding }) => styled.div `
    padding: ${(props) => props.padding || defaultPadding || "40px 56px"};
    background-color: white;
    @media ${(props) => props.theme.media.lg} {
        padding: 25px;
    }
    @media ${(props) => props.theme.media.md} {
        padding: 20px;
    }
`;
