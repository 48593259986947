import classNames from "classnames";
export const AccordionItemBase = ({ AccordionTitleFC, defaultBgColor, stepCompletedBgColor, }) => {
    const AccordionItem = ({ children, isActive, number, title, isStepCompleted, changeExactActiveStep, }) => {
        return (<div className={classNames("mb-4 px-4 py-5 lg:px-10")} style={{ backgroundColor: isStepCompleted ? defaultBgColor : stepCompletedBgColor }}>
                <AccordionTitleFC number={number} title={title} isActive={isActive} isStepCompleted={isStepCompleted} changeExactActiveStep={changeExactActiveStep}/>
                {children}
            </div>);
    };
    return AccordionItem;
};
