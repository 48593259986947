import { sessionController } from "@jog/shared";
import { signOut } from "next-auth/react";
export const signOutUser = async () => {
    try {
        localStorage.removeItem("createdCart");
        localStorage.removeItem("user-id");
        localStorage.removeItem("wishlistId");
        sessionController().clear();
        await signOut({ callbackUrl: "/login", redirect: true });
    }
    catch (e) {
        console.log(e);
    }
};
