import { lowerCaseLocale } from "../helper";
const PRISMIC_S3_ENDPOINT = (process.env.PRISMIC_S3_ENDPOINT || "").replace(/\/$/, "");
const fetchS3 = async (url) => {
    const response = await fetch(url);
    if (response.ok)
        return response.clone().json();
    const text = await response.clone().text();
    throw new Error(text || response.statusText);
};
export const fetchS3ContentPage = async (type, uid) => fetchS3(`${PRISMIC_S3_ENDPOINT}/cache/${type}__${uid}__${lowerCaseLocale()}.json`);
export const fetchS3CategoryPage = async (type, id) => fetchS3(`${PRISMIC_S3_ENDPOINT}/cache/${type}__${id}.json`);
export const fetchS3HomePage = async () => fetchS3(`${PRISMIC_S3_ENDPOINT}/cache/homepage__${lowerCaseLocale()}.json`);
export const fetchS3PromoTag = async () => await fetchS3(`${PRISMIC_S3_ENDPOINT}/cache/promo_tag__${lowerCaseLocale()}.json`);
