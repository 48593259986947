import { Arrow, ArrowButtonBase } from "@jog/react-components";
import classNames from "classnames";
import React from "react";
export const ArrowButton = React.memo(ArrowButtonBase({
    defaultBgColor: "#809A7B",
    preIconShow: true,
    ArrowButtonWrapperCss: "py-2.5 pr-7.5 pl-10 font-primary font-bold text-base hover:text-white hover:!bg-orange-main",
}));
export const ArrowButton_ = ({ children, className, ...props }) => (<button className={classNames("group flex items-center justify-center whitespace-nowrap border-none bg-green-main px-7.5 py-2.5 font-primary text-base font-bold uppercase text-white hover:bg-orange-main focus:outline-none disabled:opacity-60", className)} {...props}>
        <span className="relative w-4">
            <Arrow color="white" size="middle" className="button-arrow absolute top-1/2 -left-2.5 -translate-y-1/2 duration-300 lg:group-hover:-left-1.25"/>
        </span>
        <span>{children}</span>
    </button>);
