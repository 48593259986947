import { getVoucher } from "../aws/loyalty";
export const getVoucherBase = ({ execute, setToken }) => {
    return async (voucherCode) => {
        return process.env.NEXT_PUBLIC_USE_RB2_VOUCHER === "yes"
            ? getVoucher(voucherCode)
            : execute(await setToken({
                uri: `/loyalty/v1/vouchers/${voucherCode}`,
                method: "GET",
            }));
    };
};
