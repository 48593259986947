import { styled } from "@jog/theming";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col } from "react-bootstrap";
const FormikSelectWrapper = styled.div `
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: ${(props) => props.width || "100%"};

    .select-wrapper.disabled:before {
        display: none;
    }

    .select-wrapper {
        select {
            color: #000;
        }

        &:before {
            position: absolute;
            right: 20px;
            z-index: 1;
            font-family: icomoon-jc;
            font-weight: 400;
            content: "\\e911";
            pointer-events: none;
        }
    }
`;
export const FormikSelectFieldBase = ({ wrapperCss }) => {
    const FormikSelectFieldFC = ({ children, label, id, defaultValue, smRight, isDisabled = false, name, }) => {
        return (<FormikSelectWrapper css={wrapperCss}>
                {label && (<Col sm="4">
                        <label htmlFor={id}>{label}</label>
                    </Col>)}
                <Col sm={+(smRight || 8)}>
                    <div className={`select-wrapper${isDisabled ? " disabled" : ""}`}>
                        <Field id={id} component="select" name={name} defaultValue={defaultValue} disabled={isDisabled}>
                            {children}
                        </Field>
                    </div>
                    <ErrorMessage name={name} component="div" className="Error"/>
                </Col>
            </FormikSelectWrapper>);
    };
    return FormikSelectFieldFC;
};
