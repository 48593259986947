import classNames from "classnames";
import { useI18n, useSizeSelector } from "../../hooks";
export const SizeSelectorBase = ({ spanCls, liCls, liItemCls, disabledItemCls, selectedItemCls, }) => {
    const SizeSelectorFC = ({ sizes, setSku }) => {
        const { $t } = useI18n();
        const { totalLengthSizes, totalWaistSizes, enabledLengthSizes, enabledWaistSizes, sizesArray, setSelectedLengthSizeNum, setSelectedWaistSizeNum, selectedLengthSizeNum, selectedWaistSizeNum, } = useSizeSelector({ sizes, setSku });
        return (<div>
                {totalLengthSizes[0] ? (<span className={classNames("my-2.75 block", spanCls)}>{$t("Choose waist size")}</span>) : (<span className={classNames("my-2.75 block", spanCls)}>{$t("Choose a size")}</span>)}
                <div className="flex w-full flex-col justify-start">
                    <div>
                        <div className="ml-px flex flex-wrap">
                            {totalWaistSizes.map((waistSize) => {
                var _a;
                const hasEnabledWaistSizes = enabledWaistSizes.length
                    ? enabledWaistSizes.includes(waistSize)
                    : true;
                const isOnStock = (((_a = sizesArray.find((item) => item.waistSize === waistSize)) === null || _a === void 0 ? void 0 : _a.lengthSizesSku) || []).some((item) => item.isOnStock);
                const isEnabled = hasEnabledWaistSizes && isOnStock;
                return (<div className={classNames("mb-1.25 -ml-px -mt-px inline-block min-w-[40px] text-center", liCls)} key={waistSize}>
                                        <div onClick={() => isEnabled && setSelectedWaistSizeNum(waistSize)} className={classNames("inline-block w-full cursor-pointer hover:text-white", liItemCls, isEnabled && selectedWaistSizeNum === waistSize
                        ? `${selectedItemCls} !text-white`
                        : "", !isEnabled
                        ? `${disabledItemCls} bg-diagonal cursor-not-allowed text-gray-main`
                        : "")}>
                                            {waistSize}
                                        </div>
                                    </div>);
            })}
                        </div>
                    </div>
                </div>
                {totalLengthSizes[0] && (<>
                        <span className={classNames("my-2.75 block", spanCls)}>{$t("Choose length size")}</span>
                        <div className="flex">
                            <div className="ml-px flex flex-wrap">
                                {totalLengthSizes.map((lengthSize) => {
                    const isEnabled = enabledLengthSizes
                        ? enabledLengthSizes.lengthSizes.includes(lengthSize)
                        : true;
                    return (<div className={classNames("mb-1.25 -ml-px -mt-px inline-block min-w-[40px] text-center", liCls)} key={lengthSize}>
                                            <div onClick={() => isEnabled && setSelectedLengthSizeNum(lengthSize)} className={classNames("inline-block w-full cursor-pointer hover:text-white", liItemCls, isEnabled && selectedLengthSizeNum === lengthSize
                            ? `${selectedItemCls} !text-white`
                            : "", !isEnabled
                            ? `${disabledItemCls} bg-diagonal cursor-not-allowed text-gray-main`
                            : "")}>
                                                {lengthSize}
                                            </div>
                                        </div>);
                })}
                            </div>
                        </div>
                    </>)}
            </div>);
    };
    return SizeSelectorFC;
};
