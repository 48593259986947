import { handleCartError } from "../helper";
export const addShippingAddressBase = ({ client }) => {
    return async (cartId, version, shippingAddress) => {
        const action = {
            action: "setShippingAddress",
            address: {
                country: shippingAddress.country,
                firstName: shippingAddress.name,
                lastName: `${shippingAddress.insertion || ""} ${shippingAddress.familyName}`.trim(),
                postalCode: shippingAddress.postalCode,
                streetNumber: shippingAddress.houseNumber,
                streetName: shippingAddress.streetName,
                city: shippingAddress.city,
                additionalStreetInfo: shippingAddress.addition,
                phone: shippingAddress.phoneNumber,
                email: shippingAddress.email,
                additionalAddressInfo: shippingAddress.psfKey || "",
            },
        };
        const request = (version) => client.execute({
            method: "POST",
            uri: `/ctp/carts/${cartId}`,
            body: {
                version: version,
                actions: [action],
            },
        });
        return handleCartError(request, version, 5);
    };
};
