import { usePrefetch } from "@jog/react-components";
import { fetchS3PromoTag, transPromotionTag } from "@jog/shared";
import { useMemo } from "react";
export function prefetchPromoTag(prefetch) {
    return prefetch("promoTag", async () => fetchS3PromoTag().then((res) => transPromotionTag(res.data)));
}
export const usePromotionTag = (promotion) => {
    const { data } = usePrefetch("promoTag");
    return useMemo(() => data === null || data === void 0 ? void 0 : data[promotion === null || promotion === void 0 ? void 0 : promotion.trim().toLowerCase()], [data, promotion]);
};
