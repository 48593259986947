import { validateEmail as validateEmailBase } from "@jog/shared";
import { createContext, useCallback, useContext } from "react";
export const ValidateEmail = createContext({
    emailDomainBlackList: [],
    emailEndingWhiteList: [],
});
export const useEmailValidationCtx = () => useContext(ValidateEmail);
export const useValidateEmail = () => {
    const { emailEndingWhiteList, emailDomainBlackList } = useEmailValidationCtx();
    const validateEmail = useCallback((email) => validateEmailBase(email, emailDomainBlackList, emailEndingWhiteList), [emailDomainBlackList, emailEndingWhiteList]);
    return {
        emailDomainBlackList,
        emailEndingWhiteList,
        validateEmail,
    };
};
