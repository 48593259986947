export const createAnonymousCartBase = ({ execute, setToken }) => async () => {
    const requestWithToken = await setToken({
        uri: `/ctp/carts`,
        method: "POST",
        body: {
            currency: "EUR",
            inventoryMode: "ReserveOnOrder",
            anonymousId: "b6741665-532b-4956-9824-e7dc7d23ea69",
            locale: process.env.NEXT_PUBLIC_LOCALE,
            store: {
                key: process.env.NEXT_PUBLIC_STORE_ID,
                type: "store",
            },
        },
    });
    return execute(requestWithToken);
};
