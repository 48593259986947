import { CTABase } from "@jog/react-components";
import classNames from "classnames";
export const Button = ({ borderColor, bgColorHover, borderWidth, fontSize, color, bgColor, paddingMobile, disabled, colorHover, children, className, style, ...props }) => {
    return (<button className={classNames("inline-block border-solid bg-white px-5 font-primary text-base font-bold uppercase text-gray-base focus:outline-none lg:py-2.5", disabled ? "pointer-events-none opacity-60" : "pointer-events-auto cursor-pointer opacity-100", paddingMobile || "p-1.5", className)} onMouseMove={(e) => {
            if (bgColorHover) {
                e.currentTarget.style.backgroundColor = bgColorHover;
            }
            if (colorHover) {
                e.currentTarget.style.color = colorHover;
            }
        }} onMouseOut={(e) => {
            if (bgColorHover) {
                e.currentTarget.style.backgroundColor = bgColor || "white";
            }
            if (colorHover) {
                e.currentTarget.style.color = color || "#444a55";
            }
        }} style={{
            color,
            backgroundColor: bgColor,
            fontSize,
            borderWidth,
            borderColor,
            ...style,
        }} {...props}>
            {children}
        </button>);
};
export const CTA = ({ children, className, ...props }) => (<CTABase className={classNames("px-2.5 py-1.25 text-sm font-bold leading-normal lg:text-base", className)} {...props}>
        {children}
    </CTABase>);
