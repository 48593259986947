import fs from "fs";
import path from "path";
export const genPrismicJson = async (file, fetch) => {
    console.log(``);
    const data = await fetch();
    console.log(`Start write Prismic ${file} to file`);
    fs.writeFileSync(path.resolve() + `/src/lib/prismicio/prismic-${file}.json`, JSON.stringify(data));
    console.log(`Write Prismic ${file} success`);
    console.log(``);
    return data;
};
