export const GARCIA_GIFTCARD = "GARCIA_GIFTCARD";
export const JC_GIFTCARD = "JC_GIFTCARD";
export const PAYMENT_NAME_MAP = {
    [JC_GIFTCARD]: "Giftcard",
    [GARCIA_GIFTCARD]: "Giftcard",
};
export const BUCKAROO = {
    IDEAL: "ideal",
    PAYCONIQ: "payconiq",
    VISA: "visa",
    PAYPAL: "paypal",
    BANCONTACTMRCASH: "bancontactmrcash",
    MASTERCARD: "mastercard",
    AFTERPAY: "afterpay",
    KLARNA: "klarna",
    SOFORTUEBERWEISUNG: "sofortueberweisung",
    APPLEPAY: "applepay",
    VVVGIFTCARD: "vvvgiftcard",
    FASHIONCHEQUE: "fashioncheque",
};
export const DUTCH_WEEK = ["ma", "di", "wo", "do", "vr", "za", "zo"];
