import classNames from "classnames";
import React from "react";
import { Arrow } from "../Arrow/Arrow";
export const ArrowTextBase = ({ wrapperCss, arrowCss }) => {
    const ArrowTextFC = ({ marginBot, toggleVisibility = () => { }, text, isLeftArrow }) => {
        return (<div className={classNames("mb-8 flex cursor-pointer items-center", wrapperCss)} style={{ marginBottom: marginBot }}>
                <Arrow className={arrowCss} direction={isLeftArrow ? "left" : "right"}/>
                <div className="ml-2.5" onClick={toggleVisibility}>
                    {text}
                </div>
            </div>);
    };
    return ArrowTextFC;
};
