import { AWSClient } from "./index";
export const tweakwiseClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_TWEAKWISE_ENDPOINT || "");
export const synchronousClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_SYNCHRONOUS_ENDPOINT || "");
const CHANNEL = process.env.NEXT_PUBLIC_WEBSHOP;
const LANGUAGE = process.env.NEXT_PUBLIC_LOCALE;
export const navigation = (search) => tweakwiseClient()({
    uri: `/navigation?channel=${CHANNEL}&language=${LANGUAGE}&${search}`,
    method: "GET",
});
export const autocomplete = (searchWord) => tweakwiseClient()({
    uri: `/autocomplete?channel=${CHANNEL}&language=${LANGUAGE}&tn_q=${searchWord}&tn_cid=${process.env.NEXT_PUBLIC_TW_ROOT_CATEGORY_ID}`,
});
export const getGroup = (groupId) => tweakwiseClient()({
    uri: `/navigation-grouped?channel=${CHANNEL}&language=${LANGUAGE}&parent=${groupId}&template=${process.env.NEXT_PUBLIC_COLOR_VARIANTS_TEMPLATE}`,
    method: "GET",
});
export const navigationSearch = (search) => tweakwiseClient()({
    uri: `/navigation-search?channel=${CHANNEL}&language=${LANGUAGE}&${search}`,
    method: "GET",
});
export const getSoftPageUrls = () => synchronousClient()({ uri: `/soft-page-urls`, method: "GET" });
