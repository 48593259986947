import { handleCartError } from "../helper";
export const cleanCostBase = ({ execute }) => async (cartId, version, paymentCostId) => handleCartError(() => execute({
    uri: `/ctp/carts/${cartId}`,
    method: "POST",
    body: {
        version,
        actions: [
            {
                action: "setShippingAddress",
                address: {
                    country: process.env.NEXT_PUBLIC_COUNTRY.toUpperCase() === "US"
                        ? "FR"
                        : process.env.NEXT_PUBLIC_COUNTRY.toUpperCase(),
                },
            },
            { action: "setBillingAddress", address: {} },
            {
                action: "setShippingMethod",
                shippingMethod: process.env.NEXT_PUBLIC_DEFAULT_SHIPPING
                    ? {
                        key: process.env.NEXT_PUBLIC_DEFAULT_SHIPPING,
                        typeId: "shipping-method",
                    }
                    : {},
            },
            paymentCostId ? { action: "removeCustomLineItem", customLineItemId: paymentCostId } : null,
            { action: "recalculate", updateProductData: true },
        ].filter(Boolean),
    },
}), version);
