import classNames from "classnames";
import { useI18n } from "../../hooks";
import { Link } from "../index";
export const AddressItemBase = ({ addressContentCss, addressUpdateCss, CountryConvert }) => {
    const AddressItemFC = ({ address, title, showUpdate = true }) => {
        const { $t } = useI18n();
        return (<div className="mb-5 flex flex-col flex-nowrap justify-between">
                <div className={classNames("m-0 flex flex-col flex-nowrap p-0", addressContentCss)}>
                    {title && <h4 className="font-primary m-0 font-bold">{title}</h4>}
                    <span>
                        {address === null || address === void 0 ? void 0 : address.streetName} {address === null || address === void 0 ? void 0 : address.houseNumber} {address === null || address === void 0 ? void 0 : address.addition}
                    </span>
                    <span>
                        {address === null || address === void 0 ? void 0 : address.postalCode} {address === null || address === void 0 ? void 0 : address.city}
                    </span>
                    <span>
                        <CountryConvert countryCode={(address === null || address === void 0 ? void 0 : address.country) || ""}/>
                    </span>
                </div>
                {showUpdate && (<div className="pt-1.25">
                        <Link className={addressUpdateCss} to={`/my-account/edit-address/?addressid=${address === null || address === void 0 ? void 0 : address.uid}`}>
                            {$t("Update")}
                        </Link>
                    </div>)}
            </div>);
    };
    return AddressItemFC;
};
