import { handleCartError } from "../helper";
export const addProductBase = ({ execute }) => {
    return async ({ cartId, version, sku, quantity = 1, }) => handleCartError((version) => execute({
        method: "POST",
        uri: `/ctp/carts/${cartId}`,
        body: {
            version,
            actions: [
                {
                    action: "addLineItem",
                    sku,
                    quantity,
                    distributionChannel: {
                        key: process.env.NEXT_PUBLIC_WEBSHOP,
                        type: "channel",
                    },
                },
            ],
        },
    }), version, 5);
};
