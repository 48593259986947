export const Search = (props) => (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_424_51)">
            <path d="M12.1047 12.8119C12.3 13.0071 12.6166 13.0071 12.8118 12.8119C13.0071 12.6166 13.0071 12.3 12.8118 12.1048L12.1047 12.8119ZM9.85535 5.44852C9.85535 7.88236 7.88233 9.85538 5.44849 9.85538V10.8554C8.43462 10.8554 10.8554 8.43465 10.8554 5.44852H9.85535ZM5.44849 9.85538C3.01465 9.85538 1.04163 7.88236 1.04163 5.44852H0.041626C0.041626 8.43465 2.46236 10.8554 5.44849 10.8554V9.85538ZM1.04163 5.44852C1.04163 3.01468 3.01465 1.04166 5.44849 1.04166V0.0416565C2.46236 0.0416565 0.041626 2.46239 0.041626 5.44852H1.04163ZM5.44849 1.04166C7.88233 1.04166 9.85535 3.01468 9.85535 5.44852H10.8554C10.8554 2.46239 8.43462 0.0416565 5.44849 0.0416565V1.04166ZM8.59984 9.30698L12.1047 12.8119L12.8118 12.1048L9.30694 8.59987L8.59984 9.30698Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath>
                <rect width="13" height="13" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>);
