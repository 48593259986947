import campaign from "@/lib/prismicio/prismic-campaign.json";
import { Arrow, useI18n, useNewsletter } from "@jog/react-components";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
const Button = ({ children, ...props }) => (<button className={classNames("group flex items-center justify-center whitespace-nowrap font-primary text-base font-bold uppercase", "border-none bg-green-main text-white", "hover:bg-orange-main focus:outline-none disabled:opacity-60", "mt-4 h-[46px] w-full px-7.5 py-2.5 lg:mt-0 lg:w-auto [&_:first-child]:max-lg:hidden")} {...props}>
        <span className="relative w-4">
            <Arrow color="white" size="middle" className="button-arrow absolute top-1/2 -left-2.5 -translate-y-1/2 duration-300 lg:group-hover:-left-1.25"/>
        </span>
        <span>{children}</span>
    </button>);
export const NewsForm = ({ source, className }) => {
    const { $t } = useI18n();
    const formProps = useNewsletter();
    if (!(campaign === null || campaign === void 0 ? void 0 : campaign.defaultCampaign))
        return <></>;
    return (<Formik initialValues={{ email: "", newsletter: campaign.defaultCampaign, source }} {...formProps}>
            {({ isSubmitting, isValid }) => (<Form className={classNames(className, "relative flex min-w-full flex-col items-center lg:min-w-0 lg:flex-1")}>
                    <input type="hidden" name="newsletter"/>
                    <input type="hidden" name="source"/>
                    <div className="flex w-full flex-col lg:flex-row lg:flex-wrap">
                        <Field className="h-[46px] w-full border border-grey-dark p-3 font-primary outline-none focus:border-gray-shuttle disabled:border-transparent disabled:bg-gray-bg lg:w-auto lg:border-gray-light" type="email" name="email" placeholder={$t("Your email address")} id={`newsletter_email_${source}`}/>
                        <Button disabled={!isValid || isSubmitting}>{$t("Register")}</Button>
                        <div className="ml-5 hidden w-[140px] shrink-0 items-center font-primary text-11 text-gray-silver 2xl:flex">
                            {$t("We never share your email address with others.")}
                        </div>
                    </div>
                    <ErrorMessage render={(msg) => <div className="mt-2 block w-full text-sm">{msg}</div>} name="email"/>
                </Form>)}
        </Formik>);
};
