import { gtmPush } from "..";
export const newHeaderEvent = () => {
    return {
        usp: () => gtmPush({ event: "new_header_event", hotjar_event: "contrast_usp" }),
        icons: () => gtmPush({ event: "new_header_event", hotjar_event: "replacement_of_wishlist_login_cart" }),
        menu: () => gtmPush({ event: "new_header_event", hotjar_event: "jc_logo_centered_and_entries_below" }),
        service: () => gtmPush({ event: "new_header_event", hotjar_event: "service_category_added" }),
        store: () => gtmPush({ event: "new_header_event", hotjar_event: "storefinder_moved_to_the_left" }),
    };
};
