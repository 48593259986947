import { middleware } from "../../server/response";
export const guidedSelling = async (request, response) => {
    const { methodNotAllowed, assert, success, tryCatch } = middleware(request, response);
    if (!methodNotAllowed("GET"))
        return;
    await tryCatch(async () => {
        const key = process.env.TWEAKWISE_GUIDED_SELLING_INSTANCE_KEY;
        if (!assert(key, "No var env: TWEAKWISE_GUIDED_SELLING_INSTANCE_KEY"))
            return;
        const { query } = request;
        const qs = new URLSearchParams();
        Object.entries(query).forEach(([key, value]) => {
            if (!value)
                return;
            if (Array.isArray(value)) {
                value.forEach((v) => qs.append(key, v));
            }
            else {
                qs.append(key, value);
            }
        });
        const url = new URL(`https://gateway.tweakwisenavigator.com/filterwizard/${key}?${qs}`);
        const res = await fetch(url, { method: "GET", headers: { accept: "application/json" } }).then((res) => res.json());
        success(res);
    });
};
