import { RadioTabBase, RadioTabWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import React from "react";
// Others
const RadioWrapper = styled(RadioTabWrapperBase) `
    margin-bottom: 20px;
    padding: 16px;
    border: 1px solid #d0d2d4;
    background: ${(props) => (props.isActive ? "#f7f7f7" : "#fff")};
    color: #444a55;
    font-size: 16px;
`;
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper: RadioWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: "#809A7B",
            ".location-icon::before": {
                fontSize: "16px",
            },
        },
        ".jc-green": {
            color: "#809A7B",
        },
    },
    distanceLabelColor: "#444a55",
}));
