import { handleCartError } from "../helper";
export const deleteProductBase = ({ execute }) => {
    return async ({ cartId, version, productId, quantity, }) => handleCartError((version) => execute({
        method: "POST",
        uri: `/ctp/carts/${cartId}`,
        body: {
            version: version,
            actions: [
                {
                    action: "removeLineItem",
                    lineItemId: productId,
                    quantity,
                    distributionChannel: {
                        key: process.env.NEXT_PUBLIC_WEBSHOP,
                        type: "channel",
                    },
                },
            ],
        },
    }), version, 5);
};
