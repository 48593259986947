import { ClientBuilder } from "@commercetools/sdk-client-v2";
import fetch from "node-fetch";
export const projectKey = "ctp";
export const genRequest = (method, uri, body) => ({
    uri,
    method,
    body: JSON.stringify(body),
});
const scopes = ["manage_project:jogg-workshop-team-29,manage_project:jogg-workshop-team-30"];
// Configure authMiddlewareOptions
const EXPIRE_TIME = 1000 * 60 * 50; //50 mins, the CT token will expire after 60 mins.
export const validate = (gen) => {
    const object = {
        t: new Date().getTime(),
        client: gen(),
    };
    const checkTokenValidate = () => {
        const now = new Date().getTime();
        if (now - object.t >= EXPIRE_TIME) {
            object.client = gen();
            object.t = now;
        }
        return object.client;
    };
    return {
        execute: (request) => checkTokenValidate().execute(request),
        process: (request, fn, processOpt) => checkTokenValidate().process(request, fn, processOpt),
    };
};
export const genClient = (host = process.env.NEXT_PUBLIC_CTP_ENDPOINT || "", credentials) => {
    return validate(() => new ClientBuilder()
        .withProjectKey(projectKey) // .withProjectKey() is not required if the projectKey is included in authMiddlewareOptions
        .withClientCredentialsFlow({
        host,
        projectKey,
        credentials: credentials || {
            clientId: process.env.NEXT_PUBLIC_CTP_CLIENT_ID || "",
            clientSecret: process.env.NEXT_PUBLIC_CTP_CLIENT_SECRET || "",
        },
        scopes,
        fetch,
    })
        .withHttpMiddleware({
        host,
        fetch,
    })
        // .withLoggerMiddleware() // Include middleware for logging
        .build());
};
export const genGraphqlClient = (host = process.env.NEXT_PUBLIC_CTP_ENDPOINT || "") => validate(() => new ClientBuilder()
    .withProjectKey(projectKey) // .withProjectKey() is not required if the projectKey is included in authMiddlewareOptions
    .withClientCredentialsFlow({
    host,
    projectKey,
    credentials: {
        clientId: process.env.NEXT_PUBLIC_CTP_GRAPHQL_CLIENT_ID || "",
        clientSecret: process.env.NEXT_PUBLIC_CTP_GRAPHQL_CLIENT_SECRET || "",
    },
    scopes,
    fetch,
})
    .withHttpMiddleware({
    host,
    fetch,
})
    // .withLoggerMiddleware() // Include middleware for logging
    .build());
export const client = genClient();
export const graphqlClient = genGraphqlClient();
