import { gql } from "graphql-request";
export const categoriesApiQuery = gql `
    query ($limit: Int, $offset: Int) {
        categories(limit: $limit, offset: $offset) {
            total
            results {
                descriptionAllLocales {
                    locale
                    value
                }
                id
                key
                lastModifiedAt
                parent {
                    id
                }
                externalId
            }
        }
    }
`;
export const categoryTotal = gql `
    query {
        categories(limit: 1) {
            total
        }
    }
`;
export const categoryApiQuery = gql `
    query ($id: String!) {
        category(id: $id) {
            nameAllLocales {
                locale
                value
            }
            descriptionAllLocales {
                locale
                value
            }
            id
            key
            lastModifiedAt
            custom {
                customFieldsRaw(includeNames: ["path"]) {
                    name
                    value
                }
            }
        }
    }
`;
