import { styled } from "@jog/theming";
import React from "react";
const VoucherListSectionWrapper = styled.div `
    padding-top: 20px;
    h2.section-header {
        color: #5c616b;
        text-transform: uppercase;
    }
    hr {
        margin-bottom: 10px;
    }
`;
export const VoucherListSectionBase = ({ VoucherCard, moreInfoColor, expirationDateColor, }) => {
    const VoucherListSectionFC = ({ sectionTitle, voucherList, children, }) => {
        return (<VoucherListSectionWrapper>
                {sectionTitle && (<>
                        <h2 className="section-header">{sectionTitle}</h2>
                        <hr />
                    </>)}
                {children}
                <div className="flex h-auto w-full flex-row flex-nowrap items-center justify-start overflow-x-scroll">
                    {voucherList.map((voucher) => (<VoucherCard moreInfoColor={moreInfoColor} expirationDateColor={expirationDateColor} key={voucher.voucherCode} voucherData={voucher}/>))}
                </div>
            </VoucherListSectionWrapper>);
    };
    return VoucherListSectionFC;
};
