import { Search } from "@/components/Icon/Search";
import { useI18n } from "@jog/react-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
export const SearchForm = ({ onSelect, onSearch, }) => {
    const { $t } = useI18n();
    return (<Formik initialValues={{ store: "" }} validate={(values) => {
            const store = (values.store || "").trim();
            if (!store || store.length < 3) {
                return { store: $t("Enter at least 3 punctuation marks or valid zip code") };
            }
            if (/\d/.test(store)) {
                if (/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(store))
                    return;
                else if (/^[1-9][0-9]{3}$/.test(store))
                    return;
                else
                    return { store: $t("Enter at least 3 punctuation marks or valid zip code") };
            }
        }} validateOnChange={false} validateOnBlur onSubmit={(values) => onSearch(values.store)}>
            {() => (<Form className="mb-4 bg-white p-5 text-base text-gray-base lg:py-5 lg:px-10">
                    <div className="relative w-full sm:w-[45%]">
                        <Field className="h-[46px] w-full border border-gray-light p-3 align-top text-base text-gray-base outline-none placeholder:text-gray-silver focus:border-gray-shuttle focus:pl-3 disabled:bg-gray-bg" type="text" placeholder={$t("Enter a zip code or city name")} name="store"/>
                        <button type="submit" className="absolute right-0 top-0 flex h-[46px] cursor-pointer items-center justify-center px-4 text-center">
                            <Search width={16} height={16} className="text-gray-silver"/>
                        </button>
                        <ErrorMessage name="store" render={(msg) => <div className="mt-1 text-base text-gray-main">{msg}</div>}/>
                    </div>
                    <div className="mt-6 flex flex-wrap gap-6">
                        <div>
                            <input className="cursor-pointer" type="checkbox" id="kids" name="kids" onChange={() => onSelect("kids")}/>
                            <label className="cursor-pointer" htmlFor="kids">
                                {$t("Boys & girls")}
                            </label>
                        </div>
                        <div>
                            <input className="cursor-pointer" type="checkbox" id="tailer" name="tailer" onChange={() => onSelect("tailer")}/>
                            <label className="cursor-pointer" htmlFor="tailer">
                                {$t("jeans workshop")}
                            </label>
                        </div>
                    </div>
                </Form>)}
        </Formik>);
};
