import { styled } from "@jog/theming";
import classNames from "classnames";
const REM_BASE = 10;
const parseRemSize = (size) => {
    if (!size)
        return "";
    if (!/rem$/.test(size))
        return "";
    const rem = +size.replace(/rem$/, "");
    if (isNaN(rem))
        return "";
    return `${rem * REM_BASE}px`;
};
export const CTABase = ({ children, fontSizeMobile, borderWidth, borderColor, color, bgColor, bgColorHover, colorHover, className, }) => (<CTAStyle bgColorHover={bgColorHover} colorHover={colorHover} fontSize={parseRemSize(fontSizeMobile)} className={classNames("font-primary inline-block whitespace-nowrap border-solid bg-transparent font-normal", className)} css={{
        background: bgColor,
        borderColor,
        borderWidth,
        color,
    }}>
        {children}
    </CTAStyle>);
const CTAStyle = styled.span `
    @media ${(props) => props.theme.media.xl} {
        ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
    }
    &:hover {
        ${({ bgColorHover }) => bgColorHover && `border-color: ${bgColorHover}`};
        ${({ bgColorHover }) => bgColorHover && `background: ${bgColorHover}`};
        ${({ colorHover }) => colorHover && `color: ${colorHover}`};
    }
`;
