import { FormikSelectFieldBase } from "@jog/react-components";
import React from "react";
export const FormikSelectField = React.memo(FormikSelectFieldBase({
    wrapperCss: {
        ".select-wrapper": {
            "&:before": {
                top: "14px",
                fontSize: "13px",
                color: "#737780",
            },
        },
    },
}));
