import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";
import { GraphQLClient } from "graphql-request";
import fetch from "node-fetch";
import { lowerCaseLocale } from "../helper";
const TIME_OUT = 10000;
let client = null;
let graphQLClient = null;
export const prismicClient = (options) => {
    if (client) {
        options && prismicNext.enableAutoPreviews({ client, req: options.req, previewData: options.previewData });
        return client;
    }
    const repositoryName = prismic.getRepositoryName(process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT || "");
    client = prismic.createClient(prismic.getRepositoryEndpoint(repositoryName), {
        accessToken: process.env.NEXT_PUBLIC_PRISMIC_TOKEN,
        fetch: async (url, init) => {
            return fetch(url, init ? { ...init, timeout: init.timeout || TIME_OUT } : { timeout: TIME_OUT });
        },
        defaultParams: {
            lang: lowerCaseLocale(),
        },
    });
    options && prismicNext.enableAutoPreviews({ client, req: options.req, previewData: options.previewData });
    return client;
};
export const prismicGQLClient = (options) => {
    const repositoryName = prismic.getRepositoryName(process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT || "");
    graphQLClient = new GraphQLClient(prismic.getGraphQLEndpoint(repositoryName), {
        method: "GET",
        fetch: prismicClient(options).graphQLFetch,
        jsonSerializer: JSON,
    });
    return graphQLClient;
};
