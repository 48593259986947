import { getTwProfilekey } from "../../localStorage";
export const buckarooCheckoutBase = ({ client, setToken }) => {
    return async ({ paymentMethod, bankIssuer, ...data }) => {
        const profileKey = getTwProfilekey();
        const requestWithToken = await setToken({
            method: "POST",
            uri: "/orders/v1/payment",
            body: Object.assign({ provider: paymentMethod }, data, bankIssuer ? { bankIssuer } : {}, {
                callback: `${window.location.origin}/api/payment/redirect`,
            }),
            headers: profileKey ? { ["x-tw-profilekey"]: profileKey } : {},
        });
        return client.execute(requestWithToken);
    };
};
