import { useSocial } from "@/hooks/useSocial";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import metaJson from "@/lib/prismicio/prismic-meta.json";
import { LOGO_IMG_CLOUDINARY } from "@/util/constants";
import { getDomain } from "@jog/shared";
import { asImageSrc } from "@prismicio/helpers";
import NextHead from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";
// Organization+logo structured data
const organizationLogo = (logo, socialUrls, title) => {
    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": `${getDomain()}/Organization#`,
        url: `${getDomain()}/`,
        name: title,
        logo: logo,
        sameAs: socialUrls,
    };
};
const preconnectEndpointList = () => {
    /**
     * The preconnect directive allows the browser to setup early connections before an HTTP request
     * is actually sent to the server.
     * This includes DNS lookups, TLS negotiations, TCP handshakes
     */
    return [
        process.env.NEXT_PUBLIC_CTP_ENDPOINT,
        "https://dev.visualwebsiteoptimizer.com",
        "https://res.cloudinary.com",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "https://fonts.googleapis.com",
        "https://www.google-analytics.com",
        "https://www.google.com",
        "https://www.googletagmanager.com",
        "https://www.gstatic.com",
        "https://in.hotjar.com",
        "https://stats.g.doubleclick.net",
        "https://h.clarity.ms/",
        "https://static.cdn.prismic.io",
        "https://images.prismic.io",
    ];
};
export const Head = ({ title, isHomePage, description, robots, pathname, alternate, children }) => {
    var _a, _b;
    const meta = useMemo(() => metaJson, []);
    const layout = useLayout();
    const router = useRouter();
    const domain = getDomain();
    const _pathname = useMemo(() => {
        if (pathname)
            return pathname;
        const { asPath, defaultLocale, locale, query } = router;
        const path = query["pretty-path"] || asPath; // start with `/`
        return defaultLocale === locale ? `${path}` : `/${locale}${path}`; // start with `/`
    }, [pathname, router]);
    const currentPath = useMemo(() => `${domain}${_pathname}`, [domain, _pathname]);
    const socials = useSocial();
    const organizationJSON = useMemo(() => {
        var _a;
        if (typeof window === "undefined" || !isHomePage)
            return null;
        return JSON.stringify(organizationLogo(asImageSrc((_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo) || "", socials.map((i) => i.href), meta.structured_data_organization_title));
    }, [isHomePage, (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo, meta.structured_data_organization_title, socials]);
    return (<NextHead>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <title>{title || meta.title || "Welcome to Jeans Centre"}</title>
            {alternate === null || alternate === void 0 ? void 0 : alternate.map(({ locale, url }) => (<link rel="alternate" href={url} key={"alternate" + locale + url} hrefLang={locale}/>))}
            {(_b = meta.alternate) === null || _b === void 0 ? void 0 : _b.map(({ href, lang }, index) => (<link key={index} rel="alternate" href={href} hrefLang={lang}/>))}
            <link rel="canonical" href={currentPath}/>
            <meta name="description" content={description || meta.description || "Welcome to Jeans Centre"}/>
            <meta name="robots" content={process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
            ? robots || meta.robots || "index,follow"
            : "noindex, nofollow"}/>
            <meta property="og:title" content={meta.og_title || "Jeans Centre | Dé webshop van jouw jeans specialist"}/>
            <meta property="og:site_name" content={meta.og_site_name || "jeanscentre.nl"}/>
            <meta property="og:image" content={meta.og_image || LOGO_IMG_CLOUDINARY}/>
            <meta property="og:description" content={meta.og_description ||
            "Denk je aan jeans, dan denk je aan Jeans Centre! Al 40 jaar is Jeans Centre dé specialist op het gebied van jeans. We zijn uitgegroeid tot een brand met bijna 100 winkels en een online shop met volop keuze in jeans en eigentijdse fashion."}/>
            {isHomePage && <meta name="facebook-domain-verification" content="o8mscvc5og6nujhx6puki2954cp91f"/>}
            {preconnectEndpointList().map((href, index) => (<link key={index} rel="preconnect" href={href} crossOrigin=""/>))}
            {organizationJSON && <script type="application/ld+json">{organizationJSON}</script>}
            {children}
        </NextHead>);
};
