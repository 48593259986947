import { JCRichText } from "@/components";
import { Container, ContentLoader, useLocation } from "@jog/react-components";
import { useEffect, useMemo, useState } from "react";
import { SearchForm } from "./SearchForm";
import { StoreList } from "./StoreList";
export const StoreLocator = ({ footer, images }) => {
    const [active, setActive] = useState(0);
    const [filter, setFilter] = useState({ tailer: false, kids: false });
    const { pending, stores, onSearch } = useLocation();
    const list = useMemo(() => (stores || [])
        .filter((i) => (filter.kids ? i.hasKids : true))
        .filter((i) => (filter.tailer ? i.hasTailor : true))
        .map((i) => ({ ...i, image: images[i.storeNumber] || i.image })), [filter, images, stores]);
    useEffect(() => {
        setActive(0);
    }, [list]);
    return (<Container className="mb-16 mt-8">
            <SearchForm onSearch={onSearch} onSelect={(key) => setFilter((v) => ({ ...v, [key]: !v[key] }))}/>
            {pending ? (<div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <ContentLoader height="400px"/>
                    <ContentLoader height="400px"/>
                </div>) : (<StoreList active={active} setActive={setActive} stores={list === null || list === void 0 ? void 0 : list.slice(0, 9)}/>)}

            <div className="px-5 text-base lg:px-0">
                <JCRichText field={footer}/>
            </div>
        </Container>);
};
