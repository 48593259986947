import { layout as fetchLayout } from "@/lib/prismicio/layout";
import layoutJson from "@/lib/prismicio/prismic-layout.json";
import { usePrefetch } from "@jog/react-components";
export function prefetchLayout(prefetch) {
    return prefetch("layout", async () => {
        if (process.env.FILE_CACHE_STRATEGY === "fs") {
            return layoutJson;
        }
        return fetchLayout();
    });
}
export const useLayout = () => {
    const { data } = usePrefetch("layout");
    return data;
};
