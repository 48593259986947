import { sessionController } from "@jog/shared";
import { getSession } from "next-auth/react";
export const withAuth = async function (request) {
    try {
        const token = await sessionController().get(getSession);
        if (token) {
            request["headers"] = {
                ...request["headers"],
                Authorization: `Bearer ${token}`,
            };
        }
        return request;
    }
    catch (e) {
        console.error(e);
        return request;
    }
};
