import { getGtmIdFromCT, getPaths, lowerCaseLocale, mapAttributesFromCTP, normalizePdpImages } from "../helper";
export const buildProductDetailInfo = ({ pdpData, prismic, categoryKey, json: { pdpSizesJson, brandsJson }, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const locale = lowerCaseLocale();
    const data = pdpData.masterData.current;
    const attributes = data.masterVariant.attributesRaw;
    const published = pdpData.masterData.published;
    const desktopImages = normalizePdpImages(((_a = data.masterVariant) === null || _a === void 0 ? void 0 : _a.images) || [], prismic.desktopSortingByName || [], prismic.sortingByQuality || []);
    const mobileImages = normalizePdpImages(((_b = data.masterVariant) === null || _b === void 0 ? void 0 : _b.images) || [], prismic.mobileSortingByName || [], prismic.sortingByQuality || []);
    const isFasletShow = prismic.faslet && prismic.categoryKeys.some((item) => item === categoryKey);
    const sizeImage = (pdpSizesJson === null || pdpSizesJson === void 0 ? void 0 : pdpSizesJson.find((item) => item.key === categoryKey)) || null;
    const ctBrand = ((_c = attributes.find((i) => i.name === "brand")) === null || _c === void 0 ? void 0 : _c.value) || "";
    const brand = brandsJson
        ? (brandsJson === null || brandsJson === void 0 ? void 0 : brandsJson.find((i) => i.brandId === ctBrand.replace(" ", "_").toLowerCase())) || null
        : null;
    const gtmId = getGtmIdFromCT(attributes) || "";
    const name = data.name;
    const description = data.description;
    const slug = data.slug;
    const categories = data.categories.map((val) => val.id);
    const color = ((_e = (_d = attributes.find((i) => i.name === "webcolor")) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e[process.env.NEXT_PUBLIC_LOCALE]) || "";
    const material = ((_g = (_f = attributes.find((i) => i.name === "material")) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g[process.env.NEXT_PUBLIC_LOCALE]) || "";
    const size = ((_h = attributes.find((i) => i.name === "size")) === null || _h === void 0 ? void 0 : _h.value) || "";
    const mappedAttributes = mapAttributesFromCTP(attributes, prismic.attributes, locale);
    const sustainable = prismic.sustainable.filter((slice) => categories.includes(slice.id)).map((i) => i.items) || [];
    return {
        ctpId: pdpData.id,
        desktopImages,
        mobileImages,
        isFasletShow,
        sizeImage,
        size,
        brand,
        gtmId,
        name,
        description,
        slug,
        color,
        mappedAttributes,
        material,
        sustainable,
        paths: getPaths(attributes),
        published,
    };
};
