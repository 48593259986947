import { getCtOrders } from "../graphql";
export const success = (req, res) => (data = { message: "ok" }) => {
    res.status(200).json(data);
    return true;
};
export const assert = (req, res) => (condition, error) => {
    if (!condition) {
        res.status(400).json({ error });
        return false;
    }
    return true;
};
export const methodNotAllowed = (req, res) => (method) => {
    var _a;
    if (((_a = req.method) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== method.toUpperCase()) {
        res.status(405).json({ error: "Method not allowed" });
        return false;
    }
    return true;
};
export const forbidden = (req, res) => () => {
    res.status(403).json({ error: "Forbidden" });
    return false;
};
export const apiAuth = (req, res) => () => {
    const token = req.query.token;
    if (token) {
        if (token !== process.env.NEXT_API_AUTH) {
            res.status(403).json({ error: "Error token" });
            return false;
        }
        return true;
    }
    else {
        res.status(401).json({ error: "Token miss" });
        return false;
    }
};
export const tryCatch = (req, res) => async (fn, failCallback = console.error) => {
    try {
        await fn();
    }
    catch (e) {
        failCallback === null || failCallback === void 0 ? void 0 : failCallback(e);
        res.status(400).json({ error: e.message });
    }
};
export const middleware = (req, res) => {
    return {
        success: success(req, res),
        assert: assert(req, res),
        methodNotAllowed: methodNotAllowed(req, res),
        apiAuth: apiAuth(req, res),
        tryCatch: tryCatch(req, res),
        forbidden: forbidden(req, res),
    };
};
export const rebuildHook = async (request, response) => {
    const { methodNotAllowed, apiAuth, success, assert, tryCatch } = middleware(request, response);
    if (!methodNotAllowed("GET"))
        return;
    if (!apiAuth())
        return;
    await tryCatch(async () => {
        if (!assert(process.env.VERCEL_REBUILD_URL, "No var env: VERCEL_REBUILD_URL"))
            return;
        const res = await fetch(`${process.env.VERCEL_REBUILD_URL}?buildCache=false`).then((data) => data.json());
        console.log(res);
        success(res);
    });
};
export const slugServiceHook = async (request, response) => {
    const { methodNotAllowed, apiAuth, assert, success, tryCatch } = middleware(request, response);
    if (!methodNotAllowed("GET"))
        return;
    if (!apiAuth())
        return;
    await tryCatch(async () => {
        if (!assert(process.env.SLUG_SERVICE_END_POINT, "No var env: SLUG_SERVICE_END_POINT"))
            return;
        if (!assert(process.env.SLUG_SERVICE_AUTH, "No var env: SLUG_SERVICE_AUTH"))
            return;
        const res = await fetch(`${process.env.SLUG_SERVICE_END_POINT}`, {
            method: "POST",
            headers: {
                authorization: process.env.SLUG_SERVICE_AUTH,
            },
        }).then((res) => res.json());
        success(res);
    });
};
export const returnGetCtOrders = async (request, response) => {
    const { methodNotAllowed, assert, success, tryCatch } = middleware(request, response);
    if (!methodNotAllowed("POST"))
        return;
    await tryCatch(async () => {
        const { orderNumbers } = JSON.parse(request.body);
        if (!assert(orderNumbers, "OrderNumbers is required"))
            return;
        if (!assert(orderNumbers.length > 0, "OrderNumbers is required"))
            return;
        const resp = await getCtOrders(orderNumbers);
        success(resp);
    });
};
export const paymentRedirect = async (request, response) => {
    const { assert, tryCatch } = middleware(request, response);
    await tryCatch(async () => {
        const result = request.query.result;
        const orderId = request.query.orderId;
        if (!assert(orderId, "orderId missing"))
            return;
        switch (result) {
            case "success":
                return response.redirect(302, `/orders/payment?orderId=${orderId}`);
            case "cancel":
            case "error":
            case "reject":
                return response.redirect(302, `/checkout?result=${result}&orderId=${orderId}`);
            default:
                return response.redirect("/");
        }
    });
};
