import { sortWaistSize, typedBoolean } from "@jog/shared";
import { uniq } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
export const useSizeSelector = ({ sizes, setSku, }) => {
    const sizesArray = useMemo(() => (sizes === null || sizes === void 0 ? void 0 : sizes.map((size) => {
        const waistSize = size.waistSize;
        const lengthSizesSku = size.lengthSizes.map((lengthSize) => {
            const lengthNumber = lengthSize.lengthSize;
            const sku = lengthSize.sku;
            const isOnStock = lengthSize.isOnStock;
            return { lengthNumber, sku, isOnStock };
        });
        const lengthSizes = size.lengthSizes.map((lengthSize) => {
            const lengthNumber = lengthSize.lengthSize;
            if (lengthSize.isOnStock)
                return lengthNumber;
        });
        return { waistSize, lengthSizes, lengthSizesSku };
    })) || [], [sizes]);
    const totalWaistSizes = useMemo(() => sizesArray
        .map((size) => {
        const hasLength = size.lengthSizesSku.filter(typedBoolean).length > 0;
        if (hasLength)
            return size.waistSize;
    })
        .filter(typedBoolean)
        .sort(sortWaistSize) || [], [sizesArray]);
    const totalLengthSizes = useMemo(() => uniq(sizes === null || sizes === void 0 ? void 0 : sizes.map((size) => size.lengthSizes
        .map(({ isOnStock, lengthSize }) => isOnStock && lengthSize)
        .filter(typedBoolean)).reduce((previousValue, currentValue) => [...currentValue, ...previousValue], [])).sort(), [sizes]);
    const fullSizes = useMemo(() => {
        const sizeArr = sizes === null || sizes === void 0 ? void 0 : sizes.sort((pre, cur) => +pre.waistSize - +cur.waistSize).map((size) => {
            return size.lengthSizes
                .sort((pre, cur) => +pre.lengthSize - +cur.lengthSize)
                .map((lengthSize) => {
                return {
                    size: `${size.waistSize}${lengthSize.lengthSize ? "/" + lengthSize.lengthSize : ""}`,
                    sku: lengthSize.sku,
                    isOnStock: lengthSize.isOnStock,
                };
            });
        });
        return (sizeArr === null || sizeArr === void 0 ? void 0 : sizeArr.flat(2)) || [];
    }, [sizes]);
    const [selectedWaistSizeNum, setSelectedWaistSizeNum] = useState("");
    const [selectedLengthSizeNum, setSelectedLengthSizeNum] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const findSku = useCallback(() => {
        var _a, _b, _c, _d;
        if (selectedWaistSizeNum && selectedLengthSizeNum) {
            return (_b = (_a = sizesArray === null || sizesArray === void 0 ? void 0 : sizesArray.find((item) => (item === null || item === void 0 ? void 0 : item.waistSize) === selectedWaistSizeNum)) === null || _a === void 0 ? void 0 : _a.lengthSizesSku.find((item) => (item === null || item === void 0 ? void 0 : item.lengthNumber) === selectedLengthSizeNum)) === null || _b === void 0 ? void 0 : _b.sku;
        }
        if (selectedWaistSizeNum && !totalLengthSizes[0]) {
            return (_d = (_c = sizesArray === null || sizesArray === void 0 ? void 0 : sizesArray.find((item) => item.waistSize === selectedWaistSizeNum)) === null || _c === void 0 ? void 0 : _c.lengthSizesSku.filter((value) => value && value.isOnStock)[0]) === null || _d === void 0 ? void 0 : _d.sku;
        }
        return null;
    }, [selectedLengthSizeNum, selectedWaistSizeNum, sizesArray, totalLengthSizes]);
    const enabledLengthSizes = useMemo(() => {
        return sizesArray.find((item) => item.waistSize === selectedWaistSizeNum);
    }, [selectedWaistSizeNum, sizesArray]);
    const enabledWaistSizes = useMemo(() => {
        return sizesArray
            .filter((item) => item.lengthSizes.includes(selectedLengthSizeNum))
            .map((item) => item.waistSize);
    }, [selectedLengthSizeNum, sizesArray]);
    useEffect(() => {
        setSku === null || setSku === void 0 ? void 0 : setSku(findSku() || "");
    }, [findSku, setSku]);
    useEffect(() => {
        if (totalWaistSizes.length === 1) {
            const waistSize = totalWaistSizes[0];
            const isEnabled = enabledWaistSizes.length ? enabledWaistSizes.includes(waistSize) : true;
            setSelectedWaistSizeNum(isEnabled ? waistSize : "");
        }
        else {
            enabledWaistSizes.length === 1 && setSelectedWaistSizeNum(enabledWaistSizes[0]);
        }
    }, [enabledWaistSizes, totalWaistSizes]);
    useEffect(() => {
        if (totalLengthSizes.length === 1) {
            const isEnabled = enabledLengthSizes ? enabledLengthSizes.lengthSizes.includes(totalLengthSizes[0]) : false;
            setSelectedLengthSizeNum(isEnabled ? totalLengthSizes[0] : "");
        }
        else {
            const validLength = (enabledLengthSizes === null || enabledLengthSizes === void 0 ? void 0 : enabledLengthSizes.lengthSizes.filter(typedBoolean)) || [];
            validLength.length === 1 && setSelectedLengthSizeNum(validLength[0]);
        }
    }, [enabledLengthSizes, totalLengthSizes]);
    return {
        totalWaistSizes,
        totalLengthSizes,
        sizesArray,
        setSelectedWaistSizeNum,
        selectedWaistSizeNum,
        setSelectedLengthSizeNum,
        selectedLengthSizeNum,
        enabledWaistSizes,
        enabledLengthSizes,
        fullSizes,
        setSelectedSize,
        selectedSize,
    };
};
