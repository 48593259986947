import { getPurchase } from "@jog/shared";
import { makeAutoObservable } from "mobx";
export class PurchaseStore {
    constructor() {
        this.purchaseError = false;
        this.purchaseData = [];
        this.isLoadingPurchase = false;
        makeAutoObservable(this);
    }
    async getPurchaseData() {
        this.isLoadingPurchase = true;
        try {
            const purchaseResponse = await getPurchase();
            this.purchaseData = purchaseResponse.body.Items.sort((purchaseItemA, purchaseItemB) => {
                const dateA = purchaseItemA.date.split("-").reverse().join("-");
                const dateB = purchaseItemB.date.split("-").reverse().join("-");
                return new Date(dateB).getTime() - new Date(dateA).getTime();
            });
        }
        catch (e) {
            console.log(e);
            this.purchaseError = true;
            this.purchaseData = [];
        }
        finally {
            this.isLoadingPurchase = false;
        }
        return this.purchaseData;
    }
    getOrder(orderNumber) {
        var _a;
        return (_a = this.purchaseData.filter((purchase) => purchase.orderNumber === orderNumber)) === null || _a === void 0 ? void 0 : _a[0];
    }
}
