import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { buildURL } from "imgix-url-builder";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
export const PrismicImage = ({ className, img, sizes }) => isFilled.image(img) ? (<NextImage className={className} alt={img.alt || ""} src={img.url} sizes={sizes} width={img.dimensions.width} height={img.dimensions.height}/>) : (<></>);
export const NextImage = ({ alt, onLoad, loading = "lazy", className, blur = true, fill, width, height, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    const image = useRef(null);
    useEffect(() => {
        var _a;
        if ((_a = image.current) === null || _a === void 0 ? void 0 : _a.complete)
            setLoaded(true);
    }, []);
    const staticImage = /^\//.test(props.src);
    return (<Image width={fill ? undefined : width} height={fill ? undefined : height} fill={fill} ref={image} alt={alt || ""} className={classNames({ blur }, blur ? (loaded ? "loaded" : "pending") : "", className)} loading={loading} onLoad={(e) => {
            onLoad === null || onLoad === void 0 ? void 0 : onLoad(e);
            setLoaded(true);
        }} loader={staticImage
            ? undefined
            : (args) => {
                const staticImage = /^\//.test(args.src);
                if (staticImage) {
                    return args.src;
                }
                const url = new URL(args.src);
                const params = {
                    fit: url.searchParams.get("fit") || "max",
                    w: args.width,
                    h: undefined,
                };
                if (args.quality) {
                    params.q = args.quality;
                }
                return buildURL(args.src, params);
            }} {...props}/>);
};
