import { LogoLoader } from "@/components";
import { ServerError } from "@/components/ServerError/ServerError";
import "@/styles/index.css";
import "@/styles/whatsapp.css";
import { AdpageBody, GtmScript, LastListerProvider, LayoutErrorBoundary, LocationProvider, PendingProvider, PrefetchProvider, StatsigProvider, TrustedShopScript, WhatsappScript, useSessionInit, } from "@jog/react-components";
import { PrismicPreview } from "@prismicio/next";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import { StoresProvider } from "stores/StoresProvider";
function AppContainer({ loader, children }) {
    useSessionInit();
    return (<PrefetchProvider data={loader}>
            <TrustedShopScript />
            {process.env.NEXT_PUBLIC_SQUEEZELY_ENABLE && <Script id="squeezely-worker" src="/squeezely-worker.js"/>}
            {process.env.NEXT_PUBLIC_WHATSAPP_ENABLE && <WhatsappScript />}
            <LastListerProvider>
                <StoresProvider>
                    <GtmScript />
                    <AdpageBody>
                        <LocationProvider>
                            <PrismicPreview updatePreviewURL="/api/preview" exitPreviewURL="/api/exit-preview" repositoryName={process.env.NEXT_PUBLIC_PRISMIC_REPO}>
                                <PendingProvider Pending={LogoLoader}>
                                    <StatsigProvider>{children}</StatsigProvider>
                                </PendingProvider>
                            </PrismicPreview>
                        </LocationProvider>
                    </AdpageBody>
                    <Analytics />
                </StoresProvider>
            </LastListerProvider>
        </PrefetchProvider>);
}
export default function App(props) {
    if (props.pageProps.loader) {
        return (<LayoutErrorBoundary Fallback={() => <ServerError from="_app"/>}>
                <SessionProvider>
                    <AppContainer loader={props.pageProps.loader}>
                        <props.Component {...props.pageProps}/>
                    </AppContainer>
                </SessionProvider>
            </LayoutErrorBoundary>);
    }
    return <ServerError from="_app"/>;
}
