import { Link, useI18n } from "@jog/react-components";
export const Section = ({ children, title, updatePageLink, id, }) => {
    const { $t } = useI18n();
    return (<section id={id} className="mb-2.5 bg-white p-5 text-base text-gray-shuttle">
            {title && (<div className="pb-2.5">
                    <div className="flex items-baseline justify-between">
                        <h2 className="text-[20px] uppercase leading-normal">{title}</h2>
                        {updatePageLink && (<Link className="text-xs font-bold uppercase text-gray-silver" to={updatePageLink}>
                                {$t("Update")}
                            </Link>)}
                    </div>
                    <hr className="mt-1 border-t-gray-silver"/>
                </div>)}
            {children}
        </section>);
};
