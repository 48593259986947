export const Money = ({ centAmount }) => {
    return <>{transMoney(centAmount)}</>;
};
export const transMoney = (cent, EUR = true) => {
    if (cent < 0) {
        return `-${transMoney(cent * -1)}`;
    }
    const euro = cent < 100 ? 0 : Math.floor(cent / 100);
    const cents = Math.round(cent % 100);
    const prefix = EUR ? "€" : "";
    if (cents === 0)
        return `${prefix}${euro}.-`;
    if (cents < 10)
        return `${prefix}${euro}.0${cents}`;
    return `${prefix}${euro}.${cents}`;
};
