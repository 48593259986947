import { gtmEmail, localStorage } from "@jog/shared";
import { useSession } from "next-auth/react";
import Script from "next/script";
import { createContext, memo, useContext, useEffect, useState } from "react";
const AdpageContext = createContext({
    device: null,
    marketing: null,
});
export const useAdpage = () => useContext(AdpageContext);
const useGtmInit = () => {
    var _a, _b;
    const session = useSession();
    useEffect(() => {
        var _a, _b;
        const localEmail = localStorage.getItem("email");
        const email = ((_b = (_a = session === null || session === void 0 ? void 0 : session.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email) || localEmail;
        if (email) {
            gtmEmail(email);
            localStorage.setItem("email", email);
        }
    }, [(_b = (_a = session === null || session === void 0 ? void 0 : session.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email]);
};
export const GtmStagingEnv = () => {
    useGtmInit();
    return (<Script id="google-tag-manager">
            {`
            function push(...params) {if (!params) return;params.forEach((i) => {console.log(i);i.__t = new Date();});window.dataLayer = [...window.dataLayer, ...params];window.dataLayer.push = push;}
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push = push
            `}
        </Script>);
};
export const getAdpage = () => {
    const tagging = process.env.NEXT_PUBLIC_ADPAGE_TAGGING;
    const gtm = process.env.NEXT_PUBLIC_GTM;
    return { tagging, gtm };
};
export const AdpageHead = memo(() => {
    const { tagging, gtm } = getAdpage();
    useGtmInit();
    // if (process.env.NODE_ENV !== "production") return <></>
    if (tagging && gtm)
        return (<Script id="adpage-head" strategy="afterInteractive">{`(function(){function t(t, e, r) {
if ("cookie" === t) {
var n = document.cookie.split(";");
for (var o = 0; o < n.length; o++) {
var i = n[o].split("=");
if (i[0].trim() === e) return i[1]
}
} else if ("localStorage" === t) return localStorage.getItem(e); else if ("jsVariable" === t) return window[e]; else console.warn("invalid uid source", t)
}function e(e,r,n){var o=document.createElement("script");o.async=!0,o.src=r,e.insertBefore(o,n)}function r(r,n,o,i,a){var c,s=!1;try{var u=navigator.userAgent,f=/Version\\/([0-9\\._]+)(.*Mobile)?.*Safari.*/.exec(u);f&&parseFloat(f[1])>=16.4&&(c=t(o,i,""),s=!0)}catch(t){console.error(t)}var l=window[a]=window[a]||[];l.push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var g=r+"/script.js?id="+n+(s?"&enableCK=true":"")+(c?"&mcookie="+encodeURIComponent(c):""),d=document.getElementsByTagName("script")[0];e(d.parentNode,g,d)}r('${tagging}',"${gtm}","cookie","_taggingmk","dataLayer")})();
`}</Script>);
    return <></>;
});
AdpageHead.displayName = "AdpageHead";
export const AdpageBody = memo(({ children }) => {
    const [adpageState, setAdpageState] = useState({
        marketing: null,
        device: null,
    });
    const { tagging, gtm } = getAdpage();
    return (<AdpageContext.Provider value={adpageState}>
            {children}
            {tagging && gtm ? (<>
                    <noscript>
                        <iframe src={`${tagging}/ns.html?id=${gtm}`} height="0" width="0" className="hidden"/>
                    </noscript>
                    <Script onLoad={() => {
                var _a, _b, _c, _d;
                const device = (_b = (_a = window.taggingHelpers) === null || _a === void 0 ? void 0 : _a.getDeviceInfo) === null || _b === void 0 ? void 0 : _b.call(_a);
                const marketing = (_d = (_c = window.taggingHelpers) === null || _c === void 0 ? void 0 : _c.getMarketingObject) === null || _d === void 0 ? void 0 : _d.call(_c);
                if (device && marketing) {
                    setAdpageState({ device, marketing });
                }
            }} src={`${tagging}/user-data-minified.js`}/>
                    <Script defer src={`${tagging}/adex.js`}></Script>
                    <Script defer src={`${tagging}/settings.js`}></Script>
                </>) : (<></>)}
        </AdpageContext.Provider>);
});
AdpageBody.displayName = "AdpageBody";
export const GtmScript = () => process.env.NEXT_PUBLIC_GTM_ENABLE && process.env.NODE_ENV === "production" ? <AdpageHead /> : <GtmStagingEnv />;
