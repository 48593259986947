import classNames from "classnames";
import { Fragment, createElement } from "react";
import { useI18n } from "../../hooks";
const JCCustomPriceComponent = ({ isOldPrice, euro, cents }) => {
    const text = (<>
            {`${euro}.`}
            {!isOldPrice ? (cents ? (<sup>{cents < 10 ? `0${cents}` : cents}</sup>) : (<span>-</span>)) : cents ? (cents < 10 ? (`0${cents}`) : (cents)) : ("-")}
        </>);
    return createElement(isOldPrice ? Fragment : "strong", {}, text);
    // return createElement(Fragment, {}, text)
};
const GarCustomPriceComponent = ({ euro, cents }) => {
    return (<>
            €{euro}.{cents ? (cents < 10 ? `0${String(cents)}` : cents) : "-"}
        </>);
};
export const PriceBase = ({ type }) => {
    const PriceFC = ({ price, color, fontSize, marginLeft, marginRight, isCartSumPrice, isOldPrice, isPdpPrice, isListerPrice, isStlPrice, isNegative, className, isLabelShow = true, }) => {
        var _a;
        const centAmount = Number(price);
        let euro = centAmount < 100 ? 0 : Math.floor(centAmount / 100);
        let cents = centAmount % 100;
        if (isListerPrice || isStlPrice) {
            const priceString = price.toString();
            const priceArray = priceString.split(".");
            euro = +priceArray[0];
            cents = +(((_a = priceArray[1]) === null || _a === void 0 ? void 0 : _a.length) === 1 ? priceArray[1] + "0" : priceArray[1]);
        }
        const { $t } = useI18n();
        return (<span className={classNames("relative flex items-center whitespace-pre leading-none md:mt-0", isPdpPrice || isListerPrice ? "flex items-center" : "block items-baseline", type === "Gar"
                ? "font-primary font-medium text-black sm:text-[46px]"
                : "text-gray-base sm:text-[18px]", isPdpPrice ? (isOldPrice ? "text-[21px]" : "text-[30px]") : "text-[46px]", isCartSumPrice ? "mt-3.75" : "mt-0", className)} style={{
                marginLeft,
                marginRight,
                fontSize,
                color,
            }}>
                {isLabelShow && <span>{isOldPrice ? $t("old price") + ": " : ""}</span>}
                <span className={classNames(isOldPrice ? "line-through" : "")}>
                    {isNegative && "- "}
                    {type === "Gar" ? (<GarCustomPriceComponent isOldPrice={isOldPrice} isListerPrice={isListerPrice} euro={euro} cents={cents} isPdpPrice={isPdpPrice}/>) : (<JCCustomPriceComponent isOldPrice={isOldPrice} isListerPrice={isListerPrice} euro={euro} cents={+cents} isPdpPrice={isPdpPrice}/>)}
                </span>
            </span>);
    };
    return PriceFC;
};
