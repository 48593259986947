import { asImageSrc, asLink, asText, isFilled } from "@prismicio/helpers";
import { typedBoolean } from "../helper";
export const transNotFound = ({ title, meta_tag_title, meta_tag_description, meta_tag_robots, subtitle, list_description, image, body, }) => {
    var _a, _b;
    return ({
        meta: {
            title: asText(meta_tag_title) || "",
            description: asText(meta_tag_description) || "",
            robots: asText(meta_tag_robots) || "",
        },
        title: asText(title) || "",
        subtitle: asText(subtitle) || "",
        description: list_description,
        image: isFilled.image(image)
            ? {
                src: asImageSrc(image),
                alt: image.alt || "",
                width: ((_a = image.dimensions) === null || _a === void 0 ? void 0 : _a.width) || 0,
                height: ((_b = image.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 0,
            }
            : null,
        links: [...(body || [])]
            .filter(typedBoolean)
            .map((slice) => slice.items.map((i) => ({
            name: asText(i.name) || "",
            link: asLink(i.link) || "",
        })))
            .flat()
            .filter((i) => i.name && i.link),
    });
};
