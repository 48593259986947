import ServerErrorJson from "@/lib/prismicio/prismic-server-error-page.json";
import Image from "next/image";
export const ServerError = (props) => {
    const { image, title, logo } = ServerErrorJson;
    console.log(props);
    return (<div className="flex flex-col items-center justify-center pt-10">
            <Image quality={100} alt={image.alt} {...image}/>
            <h1 className="my-2 max-w-[800px] px-5 text-center text-sm font-normal text-gray-main lg:my-5 lg:text-xl">
                {title}
            </h1>
            <Image alt={logo.alt} {...logo}/>
        </div>);
};
