import { getUserVouchers, getVoucher, voucherApply, voucherRemove } from "@/lib/jog";
import { client } from "@/lib/prismicio/init";
import { VoucherStore } from "@jog/react-components";
import cartStore from "./cartStore";
const voucherStore = new VoucherStore({
    api: {
        getVoucher,
        getUserVouchers,
        voucherApply,
        voucherRemove,
    },
    prismic: {
        getVoucherDisclaimer: (queryOptions) => client.getSingle("voucher_disclaimer", queryOptions).then((res) => res.data.voucher_legal_disclaimer),
    },
    store: {
        cartStore,
    },
    skipVoucherCheck: false,
});
export default voucherStore;
