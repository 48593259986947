export const postNewsletter = async (newsletterUrl, emailInput, source, interests = '') => {
    try {
        await fetch(`${newsletterUrl}?email=${encodeURIComponent(emailInput === null || emailInput === void 0 ? void 0 : emailInput.trim())}&bron=${source}&${interests}`, {
            mode: "no-cors",
        });
    }
    catch (error) {
        console.log(error);
    }
};
