import { LocationIcon } from "@/components/Icon/LocationIcon";
import { NextImage, StoreWorkTime, useI18n } from "@jog/react-components";
import { typedBoolean } from "@jog/shared";
import classNames from "classnames";
import { CheckMarks } from "./CheckMark";
import { ShopInfo } from "./ShopInfo";
const container = "no-scrollbar mb-4 h-auto overflow-auto bg-white text-gray-base lg:h-[680px]";
const ShopListItem = ({ store, isActive, onClick }) => {
    const { storeType, town, image, formattedDistance, address, postalCode, phone, openings, hasCoffee, hasTailor } = store;
    const { $t } = useI18n();
    return (<div className={classNames("group cursor-pointer border-b border-gray-silver p-5 text-base last-of-type:border-0 hover:bg-gray-base hover:text-white lg:p-9", isActive ? "bg-gray-base text-white" : "bg-white text-gray-base")} onClick={() => onClick()}>
            <div className="flex justify-between font-primary font-bold">
                <span className="uppercase">{[storeType, town].filter(typedBoolean).join(" ")}</span>{" "}
                <span className="ml-1 inline-flex items-center whitespace-nowrap">
                    <LocationIcon fill="currentColor" className="mr-1" width={20} height={20}/>
                    {formattedDistance}
                </span>
            </div>
            {isActive && image && (<div className="my-2 block lg:hidden">
                    <NextImage blur={false} src={image} width={300} height={200} alt="Store image" className="h-full w-full"/>
                </div>)}
            <div>{address}</div>
            <div>{[postalCode, town].filter(typedBoolean).join(" ")}</div>
            <div className="my-5">
                Tel:
                <a href={`tel:${phone}`}>{phone}</a>
            </div>

            {!isActive ? (<div className="text-right">
                    <span className={classNames("border-b-2 pb-1 group-hover:border-white", isActive ? "border-white" : "border-gray-base")}>
                        {$t("More info")}
                    </span>
                </div>) : (<div className="block lg:hidden">
                    <StoreWorkTime openings={openings}/>
                    <CheckMarks store={store}/>
                    {hasTailor && (<div className="mt-5">
                            <div className="font-bold">{$t("Entertainment service in our jeans atelier")}</div>
                            <div>
                                {$t("We have a special jeans workshop in this shop. here you can have your newly bought jeans entertained in length for free. you can also go here to have your jeans repaired.")}
                            </div>
                        </div>)}
                    {hasCoffee && (<div className="mt-5">
                            <div className="font-bold">{$t("Coffee corner")}</div>
                            <div>
                                {$t("Do you want a nice cup of coffee while shopping? while you are looking for a new jeans or outfit, you can use our special coffee corner, where you can enjoy the most delicious coffee, cappuccino or espresso.")}
                            </div>
                        </div>)}
                </div>)}
        </div>);
};
export const StoreList = ({ stores, active, setActive, }) => {
    if (!(stores === null || stores === void 0 ? void 0 : stores.length))
        return <></>;
    return (<div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div className={container}>
                {stores === null || stores === void 0 ? void 0 : stores.map((store, index) => (<ShopListItem onClick={() => setActive(index)} key={store.storeNumber} store={store} isActive={index === active}/>))}
            </div>

            <div className={classNames(container, "hidden py-5 px-10 lg:block")}>
                <ShopInfo store={stores[active]} imageSize="base"/>
            </div>
        </div>);
};
