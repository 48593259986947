import { convertCentsToEuros, transGtmId, typedBoolean } from "../helper";
import { gtmPush } from "./index";
export const gtmRemoveFromCart = (product, quantity = 1) => {
    if (!product)
        return;
    const { name, prices, categoryAsUrl: category, brand, articleName, colorCode } = product;
    gtmPush({
        event: "removeFromCart",
        ecommerce: {
            remove: {
                products: [
                    {
                        name: name === null || name === void 0 ? void 0 : name.en,
                        id: transGtmId({ articleName, colorCode }),
                        price: parseFloat(convertCentsToEuros(prices.firstPriceInTheCart.centAmount)),
                        brand,
                        category,
                        variant: "",
                        quantity,
                    },
                ],
            },
        },
    });
};
function gtmAddToCart(actionfieldList, product, quantity = 1) {
    gtmPush({
        event: "addToCart",
        ecommerce: {
            currencyCode: "EUR",
            actionfield: { list: actionfieldList },
            add: { products: [{ ...product, variant: "", quantity, list: "" }] },
        },
    });
}
export const gtmAddLineItemToCart = (product, quantity = 1) => {
    var _a;
    if (!product)
        return;
    gtmAddToCart("", {
        brand: "",
        category: product.categoryAsUrl,
        id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
        name: (_a = product.name) === null || _a === void 0 ? void 0 : _a.en,
        price: parseFloat(convertCentsToEuros(product.prices.firstPriceInTheCart.centAmount)),
    }, quantity);
};
export const gtmCartPageBasketOverview = (lineItems) => {
    if (!(lineItems === null || lineItems === void 0 ? void 0 : lineItems.length))
        return;
    const products = lineItems
        .filter(typedBoolean)
        .map((product) => ({ id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }) }));
    gtmPush({ event: "BasketOverview", products });
};
export function gtmCheckoutCreateDataLayer(isLogged, productsData, step, optionText, email = "") {
    if (!productsData)
        return;
    const products = productsData.filter(typedBoolean).map((product) => ({
        name: product.name.en,
        id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
        price: parseFloat(convertCentsToEuros(product.prices.firstPriceInTheCart.centAmount)),
        brand: "",
        category: product.categoryAsUrl,
        variant: "",
    }));
    gtmPush({
        event: "checkout",
        ecommerce: {
            checkout: {
                actionField: { step, option: optionText || (isLogged ? "logged in" : "logged out"), email },
                products,
            },
        },
    });
}
